import "../../stylesheets/home";

import React from "react";
import ReactDOM from "react-dom";

import * as Sentry from "@sentry/browser";
import { Integrations } from "@sentry/tracing";
Sentry.init({
  dsn: window.sentry_dsn,
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 0.05,
});

import "init/home";

import { contact } from "src/triggers/home/contact";
import { header } from "src/triggers/home/header";
import { index } from "src/triggers/home/index";
import { login } from "src/triggers/home/login";
import { pricing } from "src/triggers/home/pricing";
import { bottomQuotes } from "src/triggers/home/bottomQuotes";
import { tour } from "src/triggers/home/tour";
import { signup } from "src/triggers/home/signup";

import { post } from "src/triggers/blog/post";

import { boards } from "src/triggers/dashboard/boards";
import { feedback } from "src/triggers/dashboard/feedback";
import { profile } from "src/triggers/dashboard/profile";
import { teams } from "src/triggers/dashboard/teams";

import { account } from "src/triggers/dashboard/account";
import { editPlan } from "src/triggers/dashboard/account/editPlan";
import { join } from "src/triggers/dashboard/account/join";
import { payments } from "src/triggers/dashboard/account/payments";
import { people } from "src/triggers/dashboard/account/people";
import { receipts } from "src/triggers/dashboard/account/receipts";
import { settings } from "src/triggers/dashboard/account/settings";

import { UserIcon } from "src/controls/UserIcon";

import { User } from "src/models/User";

import { Config } from "src/config";
import { Trigger } from "src/utils/trigger";

import Rails from '@rails/ujs'
Rails.start()

/* Config */

if (window.current_user) {
  const user = User.create(window.current_user);

  user.on("display_name_change", function (event, display_name) {
    const nameContainer = document.querySelector("#user .name");
    nameContainer.textContent = display_name;
  });

  Config.add({ user });

  ReactDOM.render(
    <UserIcon user={user} />,
    document.getElementById("usericon-container")
  );
}

/* Triggers */

Trigger.add("header", header);
Trigger.add("bottomquotes", bottomQuotes);
Trigger.add("contact", contact);
Trigger.add("index", index);
Trigger.add("devise/sessions/new", login);
Trigger.add("devise/sessions/create", login);
Trigger.add("devise/passwords/new", login);
Trigger.add("devise/passwords/create", login);
Trigger.add("devise/passwords/edit", login);
Trigger.add("devise/passwords/update", login);
Trigger.add("pricing", pricing);
Trigger.add("tour", tour);
Trigger.add("users/registrations/new", signup);
Trigger.add("users/registrations/create", signup);
Trigger.add("users/registrations/edit", signup);
Trigger.add("users/registrations/update", signup);

Trigger.add("blog/post", post);

Trigger.add("dashboard/boards", boards);
Trigger.add("dashboard/feedback", feedback);
Trigger.add("dashboard/profile", profile);
Trigger.add("dashboard/teams", teams);

Trigger.add("dashboard/account", account);
Trigger.add("dashboard/account/edit_plan", editPlan);
Trigger.add("dashboard/account/join", join);
Trigger.add("dashboard/account/payments", payments);
Trigger.add("dashboard/account/people", people);
Trigger.add("dashboard/account/receipts", receipts);
Trigger.add("dashboard/account/settings", settings);

(window.triggers || []).forEach(trigger => Trigger.fire(trigger));
