const login = () => {
  const form = $("form#new_user");
  const login = $("form#new_user button");

  form.on("submit", event => {
    login.removeClass("blue");
    login.addClass("gray");
    login.text("Please wait...");
    return true;
  });
};

export { login };
