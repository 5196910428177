import Raphael from "raphael";

import { SVGHelper } from "src/utils/SVGHelper";

const pricing = () => {
  const text_attrs = {
    "font-family": "PermanentMarker Regular",
    fill: "#777",
    "font-size": 30,
    stroke: "none"
  };

  const line_attrs = {
    stroke: "#777",
    "stroke-width": 2
  };

  const add_checkmarks = (className, color) => {
    let pathString =
      "M23.63591767720166,4.014815705044526C23.64251767720168,3.426615705044526,23.491117677201657,3.14866570504455,23.162117677201678,2.9568657050445495C21.809917677201668,2.168415705044538,20.446517677201655,1.3976657050445453,19.11726767720168,0.5720157050445493C18.32386767720166,0.07926570504454844,17.797467677201666,0.29821570504452666,17.364967677201662,1.0353657050445406C14.79446767720168,5.415865705044524,12.219067677201679,9.793515705044541,9.657517677201668,14.179215705044527C9.456317677201667,14.523715705044538,9.24476767720168,14.528015705044538,8.94811767720168,14.362215705044548C7.2474676772016835,13.411965705044537,5.544617677201671,12.465715705044545,3.8468176772016704,11.510515705044526C3.5413176772016755,11.338615705044532,3.3179176772016774,11.379815705044535,3.1290176772016594,11.676365705044532C2.207517677201679,13.123265705044531,1.2799676772016824,14.566415705044534,0.36101767720165867,16.01491570504453C0.1649176772016574,16.32401570504453,0.20071767720168054,16.58076570504454,0.5536676772016733,16.780515705044536C4.1187176772016585,18.797765705044526,7.6846676772016735,20.81356570504454,11.238567677201672,22.85036570504454C11.998817677201657,23.286065705044535,12.515917677201685,23.07261570504454,12.922317677201676,22.381465705044548C16.34036767720167,16.567615705044545,19.75721767720168,10.752965705044545,23.16946767720168,4.935665705044528C23.37561767720166,4.584315705044531,23.53891767720168,4.207815705044538,23.63591767720166,4.014815705044526C23.63591767720166,4.014815705044526,23.63591767720166,4.014815705044526,23.63591767720166,4.014815705044526";

    pathString = Raphael.transformPath(pathString, "s0.7");

    const elements = $(`.row .checkmark.${className}`).toArray();

    for (let element of elements) {
      element = $(element);

      const paper = Raphael(element[0], element.width(), element.height());

      const path = paper.path(pathString).attr({
        stroke: "none",
        fill: color
      });

      // Center within the element.
      const bbox = path.getBBox();

      const newX = element.width() / 2 - (bbox.x + bbox.width / 2);
      const newY = element.height() / 2 - (bbox.y + bbox.height / 2);

      path.transform(`T${newX},${newY}`);
    }
  };

  const add_cap = function() {
    var paper = SVGHelper.add_path({
      paper,
      selector: $(".cap"),
      pathString:
        "M 76,36 76,47.665 50.854,62.69 25,47.665 25,36 51.007,48.873 z",
      transformString: "s0.6",
      translate: "0,8",
      attrs: {
        fill: "#373737",
        stroke: "none"
      }
    });

    SVGHelper.add_path({
      paper,
      selector: $(".cap"),
      pathString:
        "M50.976,45.713l48.625-24.245L50.975,0.945L0.991,21.468L11.9,26.76c-0.248,6.283-0.93,18.536-1.722,22.498 c-0.655,3.277-3.825,7.947-9.762,13.88c-0.228,0.229-0.517,0.567-0.387,0.879c0.136,0.329,0.618,0.329,0.777,0.329 c1.534,0,5.715-1.416,7.888-2.863c0.318-0.213,0.608-0.32,0.862-0.32c0.953,0,1.507,1.569,2.043,3.087 c0.509,1.441,0.989,2.803,1.869,3.243c0.133,0.066,0.27,0.1,0.408,0.1c0.307,0,0.597-0.163,0.881-0.485 c1.62-1.835,2.845-9.15,2.009-15.833c-0.762-6.096-0.379-16.486,0.209-22.052L50.976,45.713z M50.324,11.165 c2.243,0,4.069,1.826,4.069,4.07c0,2.244-1.826,4.069-4.069,4.069s-4.068-1.825-4.068-4.069 C46.256,12.991,48.081,11.165,50.324,11.165z",
      transformString: "s0.6",
      translate: "0,0",
      attrs: {
        fill: "#373737",
        stroke: "none"
      }
    });
  };

  const add_sale_text = () => {
    const element = $(".plan .sale");

    const paper = Raphael(element[0], element.width(), element.height());

    const sale = paper.text(element.width() / 2, 28, "Sale").attr(text_attrs);

    const more_plans = paper
      .text(element.width() / 2, 53, "(Pricing changes soon!)")
      .attr({
        "font-family": "Museo Sans",
        "font-size": 13,
        stroke: "none",
        fill: "#777"
      });

    const bbox = more_plans.getBBox();

    const startX = bbox.x + bbox.width / 2;
    const startY = bbox.y + bbox.height + 3;
    const endX = 65;
    const endY = bbox.y + 44;
    const inflectionX = startX + 30;
    const inflectionY = startY + 30;

    const line = paper
      .path(
        `M${startX},${startY}S${inflectionX},${inflectionY},${endX},${endY}`
      )
      .attr(line_attrs);

    const bend = paper
      .path(`M${endX + 7},${endY - 7}L${endX},${endY}L${endX + 7},${endY + 7}`)
      .attr(line_attrs);
    return bend.transform("T-0.5,0r1");
  };

  const ab_test_button = () => {
    /*const button_text = Tracker.bucket(["Get Started", "Start Trial", "Try Now"]);
    const button = $(".plan.highlighted .cta");

    button.text(button_text);

    button.on("click", event => {
      event.preventDefault();

      Tracker.track("Clicked a/b tested button: " + button_text, {
        value: button_text
      }, {
        ab_test_code: "plan_cta_1"
      }, () => {
        return window.location.href = button.attr("href");
      });

      return false;
    });

    Tracker.track("Viewed a/b tested button: " + button_text, {
      value: button_text
    }, {
      ab_test_code: "plan_cta_1"
    });*/
  };

  add_checkmarks("white", "#F5F5F1");
  add_checkmarks("black", "#373737");
  add_cap();

  $(".other-pricing .container").on(
    "click",
    () =>
      (window.location.href = $(".other-pricing .container a")
        .first()
        .attr("href"))
  );

  SVGHelper.add_questionmarks();
};

export { pricing };
