import { NoteApp } from "src/api/v1";
import { Config } from "src/config";

import { UpgradeModal } from "src/controls/modals/UpgradeModal";

const people = () => {
  // Change role
  $("select").on("change", function(event) {
    const select = $(this);
    const row = select.parents(".row");
    const user_id = row.data("user-id");
    const team_id = email.data("team-id");

    const new_value = select.val();
    const old_value = select.data("saved-value");

    const success = () => {
      select.data("saved-value", new_value);

      const name = row.find(".name");
      const text = name.text();
      name.text("Saved!");
      setTimeout(
        () =>
          name.fadeOut(800, function() {
            name.text(text);
            name.show();
          }),
        400
      );
    };

    const failure = (jqXHR, textStatus, errorThrown) => {
      select.val(old_value);

      try {
        const data = JSON.parse(jqXHR.responseText);
        alert(data.failure);
      } catch (e) {
        alert(
          "There was an error changing this user's role. If you continue to receive this error please contact support."
        );
      }
    };

    NoteApp.API.Team.update_membership({
      team_id,
      user_id,
      role: new_value,
      success,
      failure
    });
  });

  // Add Member
  var email = $("#email");
  const submit = $("#submit");
  const submitText = submit.text();

  const error = $(".error");
  const errorText = error.text();

  submit.on("click", function() {
    submit.addClass("gray");
    submit.text("Please wait...");

    const success = () => {
      error.addClass("success");
      error.text("Invite sent!");
      error.hide();
      error.fadeIn();
      email.val("");
    };

    const failure = (jqXHR, textStatus, errorThrown) => {
      error.removeClass("success");
      error.hide();

      try {
        const data = JSON.parse(jqXHR.responseText);

        if (data.upgrade === true) {
          const um = new UpgradeModal(
            email.data("team-id"),
            "Upgrade for more collaborators!",
            data.failure
          );
          um.show();
        } else {
          error.text(data.failure);
          error.fadeIn();
        }
      } catch (e) {
        error.text(
          `There was an error sending your invitation. If this problem persists, please contact us a support@noteapp.com.`
        );
        error.fadeIn();
      }
    };

    const complete = function() {
      submit.removeClass("gray");
      submit.text(submitText);
    };

    NoteApp.API.Team.invite({
      team_id: email.data("team-id"),
      email: email.val().trim(),
      success,
      failure,
      complete
    });
  });

  // Removing a member
  $(".row .cta").on("click", function(event) {
    const cta = $(this);
    const row = $(cta.parents(".row"));
    const user_id = row.data("user-id");
    const team_id = email.data("team-id");

    const choice = confirm(
      "Are you sure you want to remove this user? You'll need to invite them again if you'd like them to re-join the team. \r\n\r\nPress OK to leave this team, or cancel to leave them as a member."
    );

    if (!choice) {
      return;
    }

    const success = () => row.slideUp(() => row.remove());

    const failure = (jqXHR, textStatus, errorThrown) => {
      try {
        const data = JSON.parse(jqXHR.responseText);
        alert(data.failure);
      } catch (e) {
        alert(
          "There was an error removing the member from this team. If you continue to receive this error please contact support."
        );
      }
    };

    NoteApp.API.Team.remove_member({
      team_id,
      user_id,
      success,
      failure
    });
  });
};

export { people };
