const account = () => {
  // Close account

  const close = $("#close");
  const cancel = $("#cancel");
  const description = $(".description");

  const closeText = close.text();

  close.on("click", event => {
    if (description.is(":hidden")) {
      description.slideDown();

      close.text("I'm sure. Please close my account.");
      close.removeClass("gray");
      close.addClass("red");

      cancel.fadeIn();
      cancel.css({
        display: "inline-block"
      });
    } else {
      window.location.href = "/dashboard/feedback";
    }
  });

  cancel.on("click", event => {
    description.slideUp();

    cancel.fadeOut();

    close.text(closeText);
    close.removeClass("red");
    close.addClass("gray");

    cancel.hide();
  });
};

export { account };
