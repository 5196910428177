import { NoteApp } from "src/api/v1";

const join = () => {
  const submit = $("#submit_new_user");
  const submitText = submit.text();

  const confirm = $("#confirm_current_user");
  const confirmText = confirm.text();

  // Following three items are on both versions of the page.
  const error = $(".error");
  const errorText = error.text();

  const invite_code = $("#invite_code").val();
  const team_id = $("#team_id").val();

  submit.on("click", function() {
    submit.addClass("gray");
    submit.text("Please wait...");

    const success = () => {
      window.location.href = "/dashboard";
    };

    const failure = (jqXHR, textStatus, errorThrown) => {
      error.removeClass("success");

      try {
        const data = JSON.parse(jqXHR.responseText);
        error.text(data.failure);
      } catch (e) {
        error.text(errorText);
      }

      error.hide();
      error.fadeIn();

      submit.removeClass("gray");
      submit.text(submitText);
    };

    NoteApp.API.Team.accept_invite({
      team_id,
      invite_code,
      email: $("#email")
        .val()
        .trim(),
      full_name: $("#full_name").val(),
      password: $("#password").val(),
      success,
      failure
    });
  });

  return confirm.on("click", function() {
    confirm.addClass("gray");
    confirm.text("Please wait...");

    $("#cancel").hide();

    const success = () => {
      window.location.href = "/dashboard";
    };

    const failure = (jqXHR, textStatus, errorThrown) => {
      error.removeClass("success");

      try {
        const data = JSON.parse(jqXHR.responseText);
        error.text(data.failure);
      } catch (e) {
        error.text(errorText);
      }

      error.hide();
      error.fadeIn();

      confirm.removeClass("gray");
      confirm.text(confirmText);

      $("#cancel").show();
    };

    NoteApp.API.Team.accept_invite({
      team_id,
      invite_code,
      email: $("#email")
        .val()
        .trim(),
      current_user: true,
      success,
      failure
    });
  });
};

export { join };
