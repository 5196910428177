/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS205: Consider reworking code to avoid use of IIFEs
 * DS207: Consider shorter variations of null checks
 */

import Raphael from "raphael";

class SVGHelper {
  constructor() {
    this.add_path = this.add_path.bind(this);
    this.text_along_path = this.text_along_path.bind(this);
    this.add_questionmarks = this.add_questionmarks.bind(this);
  }

  add_path(options) {
    const elements = $(options.selector);

    let { pathString } = options;
    pathString = Raphael.transformPath(
      options.pathString,
      options.transformString
    );

    options.paper = options.paper || [];

    return (() => {
      const result = [];
      for (let index = 0; index < elements.length; index++) {
        var paper;
        let element = elements[index];
        element = $(element);

        const before_val =
          (options.before != null ? options.before(element) : undefined) ||
          null;

        const width = options.width || element.width();
        const height = options.height || element.height();

        if (options.paper[index] != null) {
          paper = options.paper[index];
        } else {
          paper = Raphael(element[0], width, height);
        }

        const path = paper.path(pathString).attr(options.attrs);

        // Center within the element.
        const bbox = path.getBBox();

        let newX = paper.width / 2 - (bbox.x + bbox.width / 2);
        let newY = paper.height / 2 - (bbox.y + bbox.height / 2);

        if (options.translate != null && options.translate !== "") {
          newX += parseInt(options.translate.split(",")[0].trim());
          newY += parseInt(options.translate.split(",")[1].trim());
        }

        path.transform(`T${newX},${newY}`);
        if (options.transform != null) {
          path.transform(options.transform);
        }

        // TODO: deprecate each()
        if (options.each != null) {
          options.each(element, path);
        }
        if (options.after != null) {
          options.after(element, path, before_val);
        }

        result.push(paper);
      }
      return result;
    })();
  }

  // Adapted from here:
  // http://stackoverflow.com/questions/12828030/undefined-method-asset-data-uri-rail-3-2/16568523#16568523
  text_along_path(paper, path, message, attrs = {}) {
    let message_length = 0;
    const letters = [];
    const places = [];
    let ratio = undefined;
    let fontsize = undefined;
    let letter = undefined;
    let c = 0;
    let p = undefined;

    //since not every letter is the same width, get the placement for each letter along the length of the string
    //however, Raphael appears to group the width of letters into intervals of 4px, so this won't be perfect
    while (c < message.length) {
      letter = paper
        .text(0, 0, message[c])
        .attr({ "text-anchor": "start" })
        .attr(attrs);
      letters.push(letter);
      places.push(message_length);

      //spaces get a width of 0, so set min at 4px
      message_length += Math.max(4, letter.getBBox().width);
      c += 1;
    }
    ratio = path.getTotalLength() / message_length;
    fontsize = 10 * ratio;
    c = 0;
    return (() => {
      const result = [];
      while (c < letters.length) {
        letters[c].attr("font-size", fontsize + "px");
        p = path.getPointAtLength(places[c] * ratio);

        //there does appear to be a bug in p.alpha around 180. Here's the fix
        letters[c].attr({
          x: p.x,
          y: p.y,
          transform: "r" + (p.alpha < 180 ? p.alpha + 180 : p.alpha)
        });

        result.push((c += 1));
      }
      return result;
    })();
  }

  add_questionmarks() {
    const normal_attrs = {
      stroke: "none",
      fill: "#878787"
    };

    const highlighted_attrs = {
      fill: "#373737"
    };

    // The hover functions will share a div element meant to
    // be the popup box. Little hacky, but easiest to write.

    const popup = $(document.createElement("div"));
    popup.addClass("popup");

    const textWrapper = $(document.createElement("span"));

    popup.append(textWrapper);

    const center = $(document.createElement("div"));
    center.addClass("center");

    const tail = $(document.createElement("div"));
    tail.addClass("tail");

    center.append(tail);
    popup.append(center);

    const create_mouseenter_fn = (element, html, path) => {
      return () => {
        path.attr(highlighted_attrs);
        textWrapper.html(html);
        return element.append(popup);
      };
    };

    const create_mouseleave_fn = (element, html, path) => {
      return () => {
        path.attr(normal_attrs);
        return popup.detach();
      };
    };

    return this.add_path({
      selector: ".questionmark",
      pathString:
        "M16,1.466C7.973,1.466,1.466,7.973,1.466,16c0,8.027,6.507,14.534,14.534,14.534c8.027,0,14.534-6.507,14.534-14.534C30.534,7.973,24.027,1.466,16,1.466z M17.328,24.371h-2.707v-2.596h2.707V24.371zM17.328,19.003v0.858h-2.707v-1.057c0-3.19,3.63-3.696,3.63-5.963c0-1.034-0.924-1.826-2.134-1.826c-1.254,0-2.354,0.924-2.354,0.924l-1.541-1.915c0,0,1.519-1.584,4.137-1.584c2.487,0,4.796,1.54,4.796,4.136C21.156,16.208,17.328,16.627,17.328,19.003z",
      transformString: "s0.5",
      attrs: normal_attrs,
      before: element => {
        const html = element.html();
        element.html("");
        element.css({ visibility: "visible" });
        return html;
      },
      after: (element, path, html) => {
        element.on("mouseenter", create_mouseenter_fn(element, html, path));
        element.on("mouseleave", create_mouseleave_fn(element, html, path));
        return element.on("click", create_mouseleave_fn(element, html, path));
      }
    });
  }

  create_note(options) {
    options.pathString =
      "M26.059,0H0v17.918c2.816,2.85,10.345,8.254,26.059,8.254V0z";

    return this.add_path(options);
  }

  create_svg_element_child(data) {
    const span = $(document.createElement("span"));
    for (let key in data) {
      const value = data[key];
      span.attr(`data-${key}`, value);
    }
    return span;
  }

  render_svg_element(element) {
    return this.render_svg_elements(element);
  }

  render_svg_elements(options = {}) {
    if (!options.fill) {
      options.fill = "#2275C7";
    }
    if (!options.fill_hover) {
      options.fill_hover = options.default_fill;
    }
    if (options.selector == null) {
      options.selector = ".svg-element";
    }

    const elements = $(options.selector).toArray();

    return (() => {
      const result = [];
      for (var element of elements) {
        element = $(element);

        var paper = null;

        result.push(
          (() => {
            const result1 = [];
            for (let child of element.children().toArray()) {
              child = $(child);
              child.remove();

              const pathString = child.data("path");
              const translate = child.data("translate");
              const transformString = child.data("transform");
              const fill = child.data("fill") || options.fill;
              const fill_hover =
                child.data("fill_hover") || options.fill_hover || fill;

              const create_hover_handler = (fill, fill_hover) =>
                function(element, path) {
                  element.on("mouseenter", () => {
                    return path.attr({
                      fill: fill_hover
                    });
                  });

                  return element.on("mouseleave", () => {
                    return path.attr({
                      fill
                    });
                  });
                };

              result1.push(
                (paper = this.add_path({
                  paper,
                  selector: element,
                  pathString,
                  transformString,
                  translate,
                  width: options.width,
                  height: options.height,
                  attrs: {
                    fill,
                    stroke: "none"
                  },
                  after: create_hover_handler(fill, fill_hover)
                }))
              );
            }
            return result1;
          })()
        );
      }
      return result;
    })();
  }
}

const instance = new SVGHelper();
export { instance as SVGHelper };
