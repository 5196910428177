import { NoteApp } from "src/api/v1";
import { Config } from "src/config";

const feedback = () => {
  $("#reason").trigger("focus");

  const closeAccount = $("#close_account");
  const closeAccountText = closeAccount.text();

  const cancel = $("#cancel");

  let disabled = false;

  closeAccount.on("click", function (event) {
    event.preventDefault();

    if (disabled === true) {
      return false;
    }

    if (!$("#reason").val()) {
      alert(
        "Please tell us why you're closing your account. It helps us tremendously."
      );
      return false;
    }

    closeAccount.text("Please wait. This may take a second...");
    closeAccount
      .parent()
      .removeClass("blue")
      .addClass("gray");

    cancel.hide();

    disabled = true;

    const success = (data, textStatus, jqXHR) => {
      window.location.href = "/dashboard/successfully_closed";
    };

    const failure = function(jqXHR, textStatus, errorThrown) {
      disabled = false;

      alert(
        `Something went wrong while closing your account. Your account is not closed. Please try again later or contact support@${Config.get(
          "host"
        )}`
      );

      closeAccount.text(closeAccountText);
      closeAccount
        .parent()
        .removeClass("gray")
        .addClass("blue");

      cancel.show();
    };

    NoteApp.API.User.lights_out({
      reason: $("#reason").val(),
      success,
      failure
    });
  });
};

export { feedback };
