import { SVGHelper } from "src/utils/SVGHelper";

import { NoteApp } from "src/api/v1";
import { Config } from "src/config";

import { Account } from "src/models/Account";
import { Board } from "src/models/Board";

import { CorkSettingsModal } from "src/controls/modals/CorkSettingsModal";
import { UpgradeModal } from "src/controls/modals/UpgradeModal";

const boards = () => {
  Config.add(window.app_config || {});

  SVGHelper.add_path({
    selector: $(".add_board .paper"),
    pathString:
      "M25.979,12.896 19.312,12.896 19.312,6.229 12.647,6.229 12.647,12.896 5.979,12.896 5.979,19.562 12.647,19.562 12.647,26.229 19.312,26.229 19.312,19.562 25.979,19.562z",
    transformString: "s0.7",
    attrs: {
      fill: "#FFF",
      stroke: "none"
    },
    after: (element, path) => {
      element.on("mouseenter", () => {});
      element.on("mouseleave", () => {});
    }
  });

  const create_board_success_handler = element => {
    return (data, textStatus, jqXHR) => {
      element.data("data", data);
      prepare_board_viewlet(element, function() {
        // Find the input and give it an empty name.
        const input = element.find(".name");
        input.val("");

        // Click the edit_name button so we start out editing
        const edit_name = element.find(".edit-name");
        edit_name.click();
      });
    };
  };

  const create_board_failure_handler = new_board => {
    return (jqXHR, textStatus, errorThrown) => {
      let data;
      try {
        data = JSON.parse(jqXHR.responseText);
      } catch (e) {
        data = {
          failure:
            "Couldn't add new board: Server Error. Please try again later or contact support."
        };
      }

      if (data.upgrade) {
        const team_id = new_board.parents(".account-wrapper").data("team-id");
        new UpgradeModal(team_id).show();
      } else {
        alert(data.failure);
      }

      new_board.slideUp(() => new_board.remove());
    };
  };

  const create_export_handler = board => {
    return event => {
      NoteApp.API.Board.exportToCSV(board.string_identifier);

      event.stopPropagation();
      event.preventDefault();
      return false;
    };
  };

  const create_copy_handler = (copy, board) => {
    return event => {
      const team_id = copy.parents(".account-wrapper").data("team-id");

      const new_board = init_new_viewlet(team_id);

      NoteApp.API.Board.copy({
        string_identifier: board.string_identifier,
        success: create_board_success_handler(new_board),
        failure: create_board_failure_handler(new_board)
      });

      event.stopPropagation();
      event.preventDefault();
      return false;
    };
  };

  const create_settings_handler = board => {
    return event => {
      const csm = new CorkSettingsModal(board);
      return csm.show();
    };
  };

  const create_edit_name_handler = (edit_name, board) => {
    return event => {
      const element = $(edit_name.parents(".board"));
      const input = element.find(".name");
      const press_enter = element.find(".press-enter");

      if (input.hasClass("disabled")) {
        input.removeClass("disabled");
        input.focus();
        element.find(".button:not(.delete)").hide();
        press_enter.show();
      } else {
        input.addClass("disabled");

        let new_name = input.val();
        const old_name = input.data("name");

        if (new_name == null || new_name === "") {
          new_name = old_name;
        }

        // Always enforce a name.
        input.val(new_name);

        press_enter.hide();
        element.find(".button").show();

        if (new_name !== old_name) {
          NoteApp.API.Board.changeName({
            string_identifier: board.string_identifier,
            new_name,
            success() {
              input.data("name", new_name);
              return (board.name = new_name);
            },
            failure() {
              return alert(
                "There was an error changing your board's name. Please try again or contact support."
              );
            }
          });
        }
      }
    };
  };

  const create_delete_handler = (del, board) => {
    return event => {
      del = $(del);
      const element = $(del.parents(".board"));
      const boardWrapper = $(element.parents(".board-wrapper"));
      const nothingToSeeHere = boardWrapper.find(".nothing-to-see-here");

      const { account } = board;

      del.addClass("loading");

      const should_delete = confirm(
        "Are you sure you want to delete this board? This action is irreversible and all data will be lost.\r\n\r\nPress OK to delete your board, or cancel."
      );

      if (!should_delete) {
        del.removeClass("loading");
        event.stopPropagation();
        event.preventDefault();
        return false;
      }

      // If we have more than one board, for visual effect, tell the
      // nothingToSeeHere div to go away so the board can fade out nicely.
      if (account.boards.length > 1) {
        nothingToSeeHere.hide();
      }

      const success = () => {
        return element.fadeOut().slideUp(function() {
          board.remove();
          element.remove();
          return nothingToSeeHere.show();
        });
      };

      const failure = (jqXHR, textStatus, errorThrown) => {
        let data;
        try {
          data = JSON.parse(jqXHR.responseText);
        } catch (e) {
          data = {
            failure:
              "Couldn't delete board: Server Error. Please try again later or contact support."
          };
        }

        alert(data.failure);

        del.removeClass("loading");
        return nothingToSeeHere.show();
      };

      NoteApp.API.Board.destroy({
        string_identifier: board.string_identifier,
        success,
        failure
      });

      event.stopPropagation();
      event.preventDefault();
      return false;
    };
  };

  const init_new_viewlet = team_id => {
    const new_board = $(document.createElement("div"));
    new_board.addClass("board");
    const chugging = $(document.createElement("div"));
    chugging.addClass("chugging");

    const chuggingTextCandidates = [
      "Workin' on it.",
      "Hammering.",
      "Creating board.",
      "Cooking ingredients.",
      "Making magic happen."
    ];

    const chuggingText =
      chuggingTextCandidates[
        parseInt(Math.random() * chuggingTextCandidates.length)
      ];
    chugging.text(chuggingText);

    const background = $(document.createElement("a"));
    background.addClass("background");

    new_board.append(chugging);
    new_board.append(background);

    const board_wrapper = $(`#account-${team_id}`).find(".board-wrapper");
    board_wrapper.prepend(new_board);

    return new_board;
  };

  const prepare_board_viewlet = (element, callback) => {
    element = $(element);

    // These model things happen here because some boards are init'd
    // by the ruby code.
    const board = Board.create(element.data("data"));

    // Overwrite the data with the board object we created, so we'll
    // always be referencing the same objects.
    element.data("data", board);

    const account = Account.find(board.belongs_to);
    const user = Config.get("user");

    const click_handler = () => {
      window.location.href = `/${board.string_identifier}`;
    };

    let background = element.find(".background");

    const panel = $(document.createElement("div"));
    panel.addClass("panel");

    const name = $(document.createElement("input"));
    name.addClass("name disabled");
    name.attr("type", "text");
    name.attr("placeholder", "Enter a board name...");

    name.on("mousedown", function(event) {
      const input = $(this);
      if (input.hasClass("disabled") === true) {
        // Prevent the caret from showing up.
        input.attr("disabled", "disabled");
        click_handler(event);
        return false;
      }
    });

    const handle_blur = function(event) {
      // Find edit_name and click it.
      const input = $(this);
      element = $(input.parents(".board"));
      const edit_name = element.find(".edit-name");

      // Set a short delay, and only click edit_name if the input
      // isn't disabled. We do this so the user can use the edit_name
      // button to toggle the input.
      //setTimeout () ->
      if (input.hasClass("disabled") === false) {
        edit_name.click();
      }
    };
    //, 75

    name.on("blur", handle_blur);
    name.on("keydown", function(event) {
      const input = $(this);
      if (event.keyCode === 13) {
        // Enter key
        input.blur();
      }
    });

    const time = $(document.createElement("time"));
    time.addClass("timeago");
    time.html(board.updated_at_string);
    time.attr("datetime", new Date(board.updated_at).toISOString());

    const link = $(document.createElement("a"));
    link.addClass("link");

    const set_board_identifier = function() {
      background.attr("href", `/${board.string_identifier}`);
      name.val(board.name);
      name.data("name", board.name);
      link.attr("href", `/${board.string_identifier}`);
      link.html(`${Config.get("host")}/${board.string_identifier}`);
    };

    // Set the board identifier whenever it changes.
    board.on("string_identifier_change", () => set_board_identifier());

    // Set the board identifier on initailization.
    set_board_identifier();

    panel.append(time);
    panel.append(name);
    panel.append("<br>");
    panel.append(link);

    background.on("click", click_handler);

    const add_controls = panel => {
      const controls = $(document.createElement("div"));
      controls.addClass("controls");

      // export is a reserved word...
      const expt = $(document.createElement("div"));
      expt.addClass("export button svg-element");
      expt.attr("title", "Export to CSV");

      expt.append(
        SVGHelper.create_svg_element_child({
          path:
            "M24.345,13.904c0.019-0.195,0.03-0.392,0.03-0.591c0-3.452-2.798-6.25-6.25-6.25c-2.679,0-4.958,1.689-5.847,4.059c-0.589-0.646-1.429-1.059-2.372-1.059c-1.778,0-3.219,1.441-3.219,3.219c0,0.21,0.023,0.415,0.062,0.613c-2.372,0.391-4.187,2.436-4.187,4.918c0,2.762,2.239,5,5,5h3.404l-0.707-0.707c-0.377-0.377-0.585-0.879-0.585-1.413c0-0.533,0.208-1.035,0.585-1.412l0.556-0.557c0.4-0.399,0.937-0.628,1.471-0.628c0.027,0,0.054,0,0.08,0.002v-0.472c0-1.104,0.898-2.002,2-2.002h3.266c1.103,0,2,0.898,2,2.002v0.472c0.027-0.002,0.054-0.002,0.081-0.002c0.533,0,1.07,0.229,1.47,0.63l0.557,0.552c0.78,0.781,0.78,2.05,0,2.828l-0.706,0.707h2.403c2.762,0,5-2.238,5-5C28.438,16.362,26.672,14.332,24.345,13.904z M21.033,20.986l-0.556-0.555c-0.39-0.389-0.964-0.45-1.276-0.137c-0.312,0.312-0.568,0.118-0.568-0.432v-1.238c0-0.55-0.451-1-1-1h-3.265c-0.55,0-1,0.45-1,1v1.238c0,0.55-0.256,0.744-0.569,0.432c-0.312-0.313-0.887-0.252-1.276,0.137l-0.556,0.555c-0.39,0.389-0.39,1.024-0.001,1.413l4.328,4.331c0.194,0.194,0.451,0.291,0.707,0.291s0.512-0.097,0.707-0.291l4.327-4.331C21.424,22.011,21.423,21.375,21.033,20.986z",
          translate: "0,2",
          transform: "s0.8",
          fill: "#CCC",
          fill_hover: "#52A78E"
        })
      );

      expt.on("click", create_export_handler(board));
      controls.append(expt);

      if (user.is_admin(account)) {
        const copy = $(document.createElement("div"));
        copy.addClass("copy button svg-element");
        copy.attr("title", "Create Copy");

        copy.append(
          SVGHelper.create_svg_element_child({
            path:
              "M34.383,28.135v31.236H21.889v-43.73h31.235v6.247h12.493V3.146H9.395v56.225c0,6.895,5.601,12.494,12.494,12.494h12.494 v12.494c0,12.494,12.493,12.494,12.493,12.494h43.729V28.135H34.383z M74.989,78.113H50.002c-1.729,0-3.126-1.398-3.126-3.125 s1.397-3.123,3.126-3.123h24.987c1.726,0,3.122,1.396,3.122,3.123S76.715,78.113,74.989,78.113z M74.989,65.619H50.002 c-1.729,0-3.126-1.398-3.126-3.123c0-1.729,1.397-3.125,3.126-3.125h24.987c1.726,0,3.122,1.396,3.122,3.125 C78.111,64.221,76.715,65.619,74.989,65.619z M74.989,53.125H50.002c-1.729,0-3.126-1.398-3.126-3.125s1.397-3.124,3.126-3.124 h24.987c1.726,0,3.122,1.396,3.122,3.124S76.715,53.125,74.989,53.125z",
            transform: "s0.23",
            fill: "#CCC",
            fill_hover: "#52A78E"
          })
        );

        copy.on("click", create_copy_handler(copy, board));
        controls.append(copy);

        const settings = $(document.createElement("div"));
        settings.addClass("settings button svg-element");
        settings.attr("title", "Board Settings");

        settings.append(
          SVGHelper.create_svg_element_child({
            path:
              "M31.229,17.736c0.064-0.571,0.104-1.148,0.104-1.736s-0.04-1.166-0.104-1.737l-4.377-1.557c-0.218-0.716-0.504-1.401-0.851-2.05l1.993-4.192c-0.725-0.91-1.549-1.734-2.458-2.459l-4.193,1.994c-0.647-0.347-1.334-0.632-2.049-0.849l-1.558-4.378C17.165,0.708,16.588,0.667,16,0.667s-1.166,0.041-1.737,0.105L12.707,5.15c-0.716,0.217-1.401,0.502-2.05,0.849L6.464,4.005C5.554,4.73,4.73,5.554,4.005,6.464l1.994,4.192c-0.347,0.648-0.632,1.334-0.849,2.05l-4.378,1.557C0.708,14.834,0.667,15.412,0.667,16s0.041,1.165,0.105,1.736l4.378,1.558c0.217,0.715,0.502,1.401,0.849,2.049l-1.994,4.193c0.725,0.909,1.549,1.733,2.459,2.458l4.192-1.993c0.648,0.347,1.334,0.633,2.05,0.851l1.557,4.377c0.571,0.064,1.148,0.104,1.737,0.104c0.588,0,1.165-0.04,1.736-0.104l1.558-4.377c0.715-0.218,1.399-0.504,2.049-0.851l4.193,1.993c0.909-0.725,1.733-1.549,2.458-2.458l-1.993-4.193c0.347-0.647,0.633-1.334,0.851-2.049L31.229,17.736zM16,20.871c-2.69,0-4.872-2.182-4.872-4.871c0-2.69,2.182-4.872,4.872-4.872c2.689,0,4.871,2.182,4.871,4.872C20.871,18.689,18.689,20.871,16,20.871z",
            transform: "s0.65",
            fill: "#CCC",
            fill_hover: "#52A78E"
          })
        );

        settings.on("click", create_settings_handler(board));
        controls.append(settings);

        const edit_name = $(document.createElement("div"));
        edit_name.addClass("edit-name button svg-element");
        edit_name.attr("title", "Edit Name");

        edit_name.append(
          SVGHelper.create_svg_element_child({
            path:
              "M86.093,16.93L75.236,6.073c-1.442-1.431-3.976-1.431-5.406,0L60,15.903L22.034,53.858 c-0.339,0.35-0.612,0.754-0.808,1.201L13.1,74.042c-0.612,1.442-0.295,3.102,0.808,4.205c0.732,0.732,1.715,1.125,2.709,1.125 c0.502,0,1.016-0.098,1.507-0.306l18.983-8.137c0.448-0.186,0.852-0.459,1.19-0.808l37.965-37.954l9.83-9.83 c0.71-0.721,1.114-1.693,1.114-2.698C87.207,18.623,86.803,17.651,86.093,16.93z M33.415,64.19l-9.524,4.085l4.074-9.524 l33.87-33.859l5.439,5.439L33.415,64.19z M71.14,26.476l0.011,0.011c-0.098,0.087-0.197,0.175-0.295,0.273L71.14,26.476 l-5.45-5.45l-0.284,0.284c0.098-0.098,0.175-0.197,0.262-0.295l0.022,0.011l6.837-6.837l5.45,5.45L71.14,26.476z",
            transform: "s0.23",
            fill: "#CCC",
            fill_hover: "#52A78E"
          })
        );

        edit_name.on("click", create_edit_name_handler(edit_name, board));
        controls.append(edit_name);

        const press_enter = $(document.createElement("div"));
        press_enter.addClass("press-enter");
        press_enter.text("Press enter when finished editing.");
        press_enter.hide();

        controls.append(press_enter);

        const del = $(document.createElement("div"));
        del.addClass("delete button svg-element");
        del.attr("title", "Delete Board");

        del.append(
          SVGHelper.create_svg_element_child({
            path:
              "M82,19H71h-4.583l-3.109-7.461v0c-0.727-1.71-1.951-2.996-3.366-3.951C58.516,6.649,56.858,6.013,55,6H43 c-1.873,0.012-3.54,0.671-4.947,1.647c-1.398,0.99-2.588,2.331-3.239,4.087l-2.633,7.241L32.25,19H28H17c-2.209,0-4,1.791-4,4 s1.791,4,4,4h4v61c-0.001,1.927,0.794,3.691,2.054,4.946c1.255,1.26,3.02,2.055,4.946,2.054h43 c1.927,0.001,3.691-0.794,4.946-2.054c1.26-1.255,2.055-3.02,2.054-4.946V27h4c2.209,0,4-1.791,4-4S84.209,19,82,19z M40.452,13.785c0.1-0.312,0.494-0.85,1.042-1.223C42.032,12.174,42.673,11.988,43,12h12c0.342-0.013,1.03,0.181,1.612,0.581 c0.593,0.384,1.037,0.945,1.156,1.265v0L59.916,19h-21.36L40.452,13.785z M72,88c-0.001,0.273-0.105,0.509-0.296,0.704 C71.509,88.895,71.273,88.999,71,89H28c-0.273-0.001-0.509-0.105-0.704-0.296C27.105,88.509,27.001,88.273,27,88V27h45V88z",
            transform: "s0.25",
            fill: "#CCC",
            fill_hover: "#E74C3C"
          })
        );

        del.append(
          SVGHelper.create_svg_element_child({
            path:
              "M36,82c1.657,0,3-1.343,3-3V36c0-1.657-1.343-3-3-3s-3,1.343-3,3v43C33,80.657,34.343,82,36,82z",
            translate: "-3,2",
            transform: "s0.25",
            fill: "#CCC",
            fill_hover: "#E74C3C"
          })
        );

        del.append(
          SVGHelper.create_svg_element_child({
            path:
              "M62,82c1.657,0,3-1.343,3-3V36c0-1.657-1.343-3-3-3s-3,1.343-3,3v43C59,80.657,60.343,82,62,82z",
            translate: "0,2",
            transform: "s0.25",
            fill: "#CCC",
            fill_hover: "#E74C3C"
          })
        );

        del.append(
          SVGHelper.create_svg_element_child({
            path:
              "M49,82c1.657,0,3-1.343,3-3V36c0-1.657-1.343-3-3-3s-3,1.343-3,3v43C46,80.657,47.343,82,49,82z",
            translate: "3,2",
            transform: "s0.25",
            fill: "#CCC",
            fill_hover: "#E74C3C"
          })
        );

        del.on("click", create_delete_handler(del, board));
        controls.append(del);
      }

      panel.append(controls);
    };

    element.append(panel);

    add_controls(panel);

    SVGHelper.render_svg_elements({
      selector: panel.find(".svg-element")
    });

    panel.hide();
    panel.fadeIn(() => element.find(".chugging").remove());

    if (callback) {
      callback();
    }

    if (board.preview_image_url) {
      background = element.find(".background");

      const image = $(document.createElement("img"));

      image.on("error", () => {
        image.attr("src", window.app_config?.availableThemes?.cork?.preview);
      })

      image.attr("loading", "lazy");
      image.attr("src", board.preview_image_url);
      image.fadeIn();

      background.append(image);
    }
  };

  const add_board_handler = function(event) {
    const team_id = $(this)
      .parents(".account-wrapper")
      .data("team-id");

    const new_board = init_new_viewlet(team_id);

    NoteApp.API.Board.create({
      team_id,
      success: create_board_success_handler(new_board),
      failure: create_board_failure_handler(new_board)
    });
  };

  $(".add_board").on("click", add_board_handler);
  $(".add_board_in_text").on("click", add_board_handler);

  // Prepare board panes
  $(".board:not(.add)").each((index, element) => {
    prepare_board_viewlet(element);
  });

  // Search Box
  const search = $("#search");
  search.on("keyup", event => {
    let board, visible;
    const code = event.keyCode || event.which;

    let boards = $(".board");

    // Enter key
    if (code === 13) {
      visible = $(".board:visible");

      if (visible[0]) {
        board = $(visible[0]).data("data");
        window.location.href = `/${board.string_identifier}`;
        return;
      }
    }

    const val = search
      .val()
      .trim()
      .toLowerCase();

    boards.show();

    if (!val) {
      $(".nothing-to-see-here").each(function() {
        return $(this).text($(this).data("empty-text"));
      });

      $(".enter-hint").hide();
      return;
    }

    $(".nothing-to-see-here").text("Nothing to see here.");

    // We may have trouble with "", as I thought I saw some
    // when the input had a trailing space. But doesn't look like
    // it. Keep a look out.
    const values = val.split(new RegExp(" +"));

    // Search through all board identifiers to see if they match.
    // If there are multiple words in the search box, the identifier
    // must contain all words.
    for (let value of values) {
      boards = $(".board:visible");

      // Note: Jquery's filter
      boards = boards.filter((index, board) => {
        const model = $(board).data("data");

        // Are we currently adding one, for instance?
        if (model == null) {
          return true;
        }

        return (
          model.name.toLowerCase().indexOf(value) === -1 &&
          model.string_identifier.toLowerCase().indexOf(value) === -1
        );
      });

      boards.hide();
    }

    // Show the "enter hint", telling the user they can press enter
    // to navigate to the top board.
    visible = $(".board:visible");

    if (visible[0]) {
      board = $(visible[0]).data("data");
      $(".enter-hint")
        .html(`Press enter for ${board.name}`)
        .show();
    } else {
      $(".enter-hint").hide();
    }
  });
};

export { boards };
