import { v4 as uuid } from "uuid";
import Rails from "@rails/ujs";

class Tracker {
  // For when cookies are disabled. At least have a guid
  // that's the same across this instance of the tracker.
  instance_id;

  constructor() {
    this.instance_id = uuid();
  }

  version_string() {
    return "1";
  }

  getCookie(c_name) {
    let c_value = document.cookie;
    let c_start = c_value.indexOf(" " + c_name + "=");
    if (c_start === -1) {
      c_start = c_value.indexOf(c_name + "=");
    }
    if (c_start === -1) {
      c_value = null;
    } else {
      c_start = c_value.indexOf("=", c_start) + 1;
      let c_end = c_value.indexOf(";", c_start);
      if (c_end === -1) {
        c_end = c_value.length;
      }
      c_value = unescape(c_value.substring(c_start, c_end));
    }
    return c_value;
  }

  setCookie(c_name, value, exdays) {
    const exdate = new Date();
    exdate.setDate(exdate.getDate() + exdays);
    const c_value =
      escape(value) + (!exdays ? "" : "; expires=" + exdate.toUTCString());
    document.cookie = c_name + "=" + c_value;
  }

  visitor_id() {
    return this.getCookie("visitor_id");
  }

  is_tracked() {
    return this.visitor_id() != null;
  }

  identify() {
    if (!this.is_tracked()) {
      this.setCookie("visitor_id", uuid(), 31);

      // Second if statment here to double check that cookies
      // can be set. If it's still not tracked now, then the user
      // has cookies disabled, so we shouldn't send an Identify.
      if (this.is_tracked()) {
        return this.track("Identify", {
          url: window.location.href
        });
      }
    }
  }

  track(event, properties, options, callback) {
    if (!event) {
      throw "Event name must be passed!";
    }

    if (typeof options === "function") {
      callback = options;
      options = null;
    }

    if (!callback) {
      callback = () => {};
    }
    if (!options) {
      options = {};
    }

    const payload = {
      version: this.version_string(),
      event,
      properties,
      visitor_id: this.visitor_id(),
      instance_id: this.instance_id,
      useragent: navigator.userAgent,
      timestamp: new Date().toISOString()
    };

    for (let key in options) {
      const value = options[key];
      if (!options.hasOwnProperty(key)) {
        continue;
      }

      payload[key] = value;
    }

    Rails.ajax({
      type: "POST",
      url: "/event/create",
      data: JSON.stringify(payload),
      complete: callback,
      processData: false,
      contentType: "application/json"
    });
  }

  bucket(choices, id) {
    id = id || this.visitor_id() || this.instance_id;

    const amount = choices.length;
    let bounds = 0;

    for (let i = 0; i < id.length; i++) {
      var numeral;
      const c = id[i];
      if (c === "-") {
        continue;
      }
      if (parseInt(c) >= 0) {
        numeral = parseInt(c);
      } else {
        switch (c) {
          case "a":
            numeral = 10;
            break;
          case "b":
            numeral = 11;
            break;
          case "c":
            numeral = 12;
            break;
          case "d":
            numeral = 13;
            break;
          case "e":
            numeral = 14;
            break;
          case "f":
            numeral = 15;
            break;
        }
      }

      bounds += numeral;
    }

    return choices[bounds % amount];
  }
}

const instance = new Tracker();
export { instance as Tracker };
