import { NoteApp } from "src/api/v1";
import { Config } from "src/config";

import { UserSettingsModal } from "src/controls/modals/UserSettingsModal";

const profile = () => {
  // Upload photo
  const file_upload = $("#file_upload");

  file_upload.fileupload({
    url: NoteApp.API.secureURL("/user/profile_picture"),
    singleFileUploads: true,
    sequentialUploads: false,
    dataType: "json"
  });

  let fade_timeout = null;

  file_upload.on("fileuploadsend", function(event) {
    // Fade out after browser is done animating.
    fade_timeout = setTimeout(
      () =>
        $(".picture > img")
          .stop()
          .animate({
            opacity: 0
          }),
      400
    );

    return $(".picture").addClass("uploading");
  });

  file_upload.on("fileuploaddone", (event, data) => {
    const { result } = data;

    clearTimeout(fade_timeout);

    const picture_load = () => {
      $(".picture img")
        .stop()
        .animate({
          opacity: 1
        });

      $(".picture img").off("load", picture_load);

      $(".picture").removeClass("uploading");
    };

    if (!$(".picture img").length) {
      $(".picture").append("<img>");
    }

    $(".picture img").on("load", picture_load);

    $(".picture img").attr("src", result.image_url);
    Config.get("user").set("image_url", result.image_url);
  });

  file_upload.on("fileuploadfile", (event, data) => {
    alert(
      "The file you chose was either invalid or corrupt. Please choose another image."
    );
  });

  // Profile Settings & Billing Info
  $("#edit_profile").on("click", () => {
    const modal = new UserSettingsModal();
    modal.show(["Name", "Email", "Password"], "Edit Profile");
  });

  // Listen to name change events.
  // We'll listen for a display name change (which is a change to the first name or the last name)
  // but update the full name regardless.
  Config.get("user").on("display_name_change", () =>
    $("#name").html(Config.get("user").fullName())
  );
};

export { profile };
