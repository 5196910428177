import { NoteApp } from "src/api/v1";

const editPlan = () => {
  const planArray = $(".plan").toArray();

  for (let plan of planArray) {
    plan = $(plan);

    plan.on("click", () => {
      $(".plan").removeClass("selected");
      plan.addClass("selected");

      const radio = plan.find("input");
      radio.prop("checked", true);
    });
  }

  const change_plan = $("#change_plan");
  const buttonText = change_plan.text();

  const info = $(".info");

  let submitting = false;

  change_plan.on("click", () => {
    if (submitting) {
      return;
    }

    submitting = true;

    change_plan.removeClass("blue");
    change_plan.addClass("gray");
    change_plan.text("Please wait...");

    const team_id = change_plan.data("account-id");

    const success = function(data, textStatus, jqXHR) {
      info.text("Plan changed successfully.");
      info.hide();
      info.fadeIn();
      info.removeClass("error");

      // Cop out.
      $(".extra").hide();
    };

    const failure = (jqXHR, textStatus, errorThrown) => {
      let data;
      try {
        data = JSON.parse(jqXHR.responseText);
      } catch (e) {
        data = {
          failure: "Server Error. Please try again later or contact support."
        };
      }

      info.text(data.failure);
      info.addClass("error");
      info.hide();
      info.fadeIn();
    };

    const complete = function() {
      submitting = false;
      change_plan.removeClass("gray");
      change_plan.addClass("blue");
      change_plan.text(buttonText);
    };

    NoteApp.API.Team.Billing.change_plan({
      team_id,
      plan_code: $("input:radio[name=new_plan]:checked").val(),
      success,
      failure,
      complete
    });
  });
};

export { editPlan };
