import { SVGHelper } from "src/utils/SVGHelper";

const header = () => {
  var paper = SVGHelper.add_path({
    selector: $(".link .paper"),
    paper,
    pathString: "M26.059,0H0v17.918c2.816,2.85,10.345,8.254,26.059,8.254V0z",
    transform: "T2,25",
    attrs: {
      fill: "#AAA",
      stroke: "none"
    }
  });

  SVGHelper.add_path({
    selector: $(".link .paper"),
    paper,
    pathString: "M26.059,0H0v17.918c2.816,2.85,10.345,8.254,26.059,8.254V0z",
    transform: "T0,23",
    attrs: {
      fill: "#FFF",
      stroke: "none"
    }
  });
};

export { header };
