class Trigger {
  triggers;

  constructor() {
    this.triggers = {};
  }

  add(name, fn) {
    this.triggers[name] = fn;
  }

  has(name) {
    return !!this.triggers[name];
  }

  fire(name) {
    const fn = this.triggers[name];
    if (fn) {
      fn();
    }
  }
}

const instance = new Trigger();
export { instance as Trigger };
