import { NoteApp } from "src/api/v1";
import { Config } from "src/config";

const receipts = () => {
  const save_button = $("#change_receipt_email");
  const team_id = save_button.data("account-id");
  const email = $("#email");
  const hint = $("#hint-container");
  const invoices_container = $("#invoices-container");

  const showLoading = () => {
    const div = $(document.createElement("div"));
    div.addClass("ei-loading-big-white");
    div.css({
      width: "50%",
      height: "100px",
      "background-position": "center",
      "background-repeat": "no-repeat"
    });

    invoices_container.append(div);
  };

  const hideLoading = () => {
    $(".ei-loading-big-white").hide();
  };

  const changeHint = function(text) {
    hint.hide();
    hint.html(text);
    hint.fadeIn();
  };

  save_button.on("click", () => {
    save_button.text("Please wait...");
    save_button.addClass("disabled");

    const success = function(data, textStatus, jqXHR) {
      changeHint("Email changed successfully.");
      save_button.text("Save");
      save_button.removeClass("disabled");
    };

    const failure = function(jqXHR, textStatus, errorThrown) {
      let data;
      try {
        data = JSON.parse(jqXHR.responseText);
      } catch (e) {
        data = {
          failure: "Server error. Please try again later or contact support."
        };
      }

      changeHint(data.failure);
      save_button.text("Save");
      save_button.removeClass("disabled");
    };

    NoteApp.API.Team.Billing.change_receipt_email({
      team_id: save_button.data("account-id"),
      receipt_email: email.val().trim(),
      success,
      failure
    });
  });

  showLoading();

  const populateReceipts = data => {
    const invoices = data["invoices"];

    for (let invoice of invoices) {
      const wrapper = $(document.createElement("div"));
      let formattedAmount = invoice["total"];
      formattedAmount = formattedAmount.toFixed(2);
      wrapper.append("Billed $" + formattedAmount + " on " + invoice["created_at"] + " ");
      const invoiceURL = NoteApp.API.Team.Billing.invoiceURL(
        team_id,
        invoice.invoice_number
      );
      const invoiceLink = $(document.createElement("a"));
      invoiceLink.attr("href", invoiceURL);
      invoiceLink.attr("target", "_blank");
      invoiceLink.html("(PDF)");
      wrapper.append(invoiceLink);
      invoices_container.append(wrapper);
    }
  };

  NoteApp.API.Team.Billing.receipts({
    team_id,
    success(data, textStatus, xmlHttpRequest) {
      hideLoading();
      populateReceipts(data);
    },

    error(jqXHR, textStatus, errorThrown) {
      alert(
        "Error retrieving billing information. Please try again later or contact support@noteapp.com."
      );
    }
  });
};

export { receipts };
