import { Tracker } from "src/utils/tracker";
import { SVGHelper } from "src/utils/SVGHelper";

import { NoteApp } from "src/api/v1";
import { Config } from "src/config";

const signup = () => {
  let element;
  const is_upgrading = () => {
    return $("#team_id").length === 1;
  };

  const needs_billing = () => {
    return $("#payment").length === 1;
  };

  const is_education = () => {
    return get_selected_option().data("plan").is_education;
  };

  const get_selected_option = () => {
    return $(".option:checked");
  };

  const get_selected_short_code = () => {
    return get_selected_option().data("plan").short_code;
  };

  const get_all_form_elements = () => {
    let elements = $("input");
    elements = elements.add($("select"));
    elements = elements.add($("[data-recurly=number]"));
    elements = elements.add($("[data-recurly=month]"));
    elements = elements.add($("[data-recurly=year]"));
    elements = elements.add($("[data-recurly=cvv]"));
    return elements.toArray();
  };

  const get_all_error_elements = () => {
    return $(".errors").toArray();
  };

  const show_success_popup = (data) => {
    const success = $(".success");
    return success.css("display", "block");
  };

  const elements_touched = {};

  const touch = (element) => {
    if (element != null) {
      elements_touched[element.attr("id")] = true;
    }
  };

  const has_been_touched = (e) => {
    return !!elements_touched[e.attr("id")];
  };

  const create_validate_fn = (element) => {
    return () => {
      touch(element);
      return validate();
    };
  };

  let submitting = false;

  const form = $("#signup_form");
  const submit = $("#submit_button");

  const submitText = submit.text();

  const full_name = $("#full_name");
  const organization = $("#organization");
  const email = $("#email");
  const password = $("#password");
  const recaptcha_response = $("#g-recaptcha-response");

  const name_on_card = $("#name_on_card");

  for (element of get_all_form_elements()) {
    element = $(element);
    if (element.prop("tagName").toLowerCase() === "input") {
      element.on("blur", create_validate_fn(element));
    } else {
      element.on("change", create_validate_fn(element));
    }
  }

  const validate_recurly = (state) => {
    if (state.fields.number.focus) {
      return touch($("[data-recurly=number]"));
    } else if (state.fields.month.focus) {
      return touch($("[data-recurly=month]"));
    } else if (state.fields.year.focus) {
      return touch($("[data-recurly=year]"));
    } else if (state.fields.cvv.focus) {
      return touch($("[data-recurly=cvv]"));
    } else {
      return validate();
    }
  };

  if (!!window.recurly) {
    recurly.on("change", validate_recurly);
  }

  const reset_form = () => {
    submitting = false;
    submit.removeClass("gray");
    submit.addClass("blue");
    submit.text(submitText);
  };

  const matchName = (name) => {
    return name.trim().match(/^([^ ]+).* ([^ ]+)$/);
  };

  const get_tracker_info = () => {
    const elements = get_all_form_elements();

    const data = {};

    for (element of elements) {
      element = $(element);
      let value = element.val();

      if (element.attr("type") === "radio") {
        value = element.prop("checked");
      }

      data[element.attr("id")] = value;
    }

    delete data["name_on_card"];
    delete data["password"];

    data["errors"] = {};
    const error_elements = get_all_error_elements();

    for (let error_element of error_elements) {
      error_element = $(error_element);
      data.errors[error_element.attr("id")] = error_element.text();
    }

    data["selected_option"] = get_selected_option().data("plan");
    return data;
  };

  const touch_all = () => {
    const elements = get_all_form_elements();
    for (element of elements) {
      touch($(element));
    }
  };

  const get_element_name = (element) => {
    const label = $(`label[for='${element.attr("id")}']`);
    return label.text().replace("*", "");
  };

  const validate_presence = (element, list) => {
    if (element.val().trim() === "") {
      list.push(`${get_element_name(element)} cannot be blank`);
      return false;
    }
    return true;
  };

  const validate_full_name = (element, list) => {
    if (matchName(element.val()) == null) {
      list.push(
        `${get_element_name(element)} must be in the format First Last`
      );
      return false;
    }
    return true;
  };

  const validate_length = (element, length, list) => {
    length = parseInt(length);
    if (element.val().trim().length < length) {
      list.push(
        `${get_element_name(element)} must be ${length} characters or longer`
      );
      return false;
    }
    return true;
  };

  const validate_email = (element, list) => {
    if (
      element
        .val()
        .trim()
        .match(/.+@.+\..+/) == null
    ) {
      list.push(
        `${get_element_name(element)} must be of the form email@domain.com`
      );
      return false;
    }
    return true;
  };

  const validate_card_number = (element, list) => {
    const state = recurly.hostedFields.state;
    if (state.number.focus) {
      return false;
    }
    if (state.number.empty) {
      list.push("Card Number cannot be blank");
      return false;
    }
    if (!state.number.valid) {
      list.push("Card Number does not appear to be valid");
      return false;
    }
    return true;
  };

  const validate_card_expiration = (element, list) => {
    let message;
    const state = recurly.hostedFields.state;
    if (state.month.focus || state.year.focus) {
      return false;
    }
    let result = true;

    if (state.month.empty && has_been_touched($("[data-recurly=month]"))) {
      message = "Expiration Month cannot be blank";
      if (list.indexOf(message) < 0) {
        list.push(message);
      }
      result = false;
    }
    if (state.year.empty && has_been_touched($("[data-recurly=year]"))) {
      message = "Expiration Year cannot be blank";
      if (list.indexOf(message) < 0) {
        list.push(message);
      }
      result = false;
    }

    if (result && (!state.month.valid || !state.year.valid)) {
      if (
        has_been_touched($("[data-recurly=month]")) &&
        has_been_touched($("[data-recurly=year]"))
      ) {
        message = "Expiration Date does not appear to be valid";
        if (list.indexOf(message) < 0) {
          list.push(message);
        }
      }
      result = false;
    }

    return result;
  };

  const validate_card_cvv = (element, list) => {
    const state = recurly.hostedFields.state;
    if (state.cvv.focus) {
      return false;
    }
    if (state.cvv.empty) {
      list.push("CVV cannot be blank");
      return false;
    }
    if (!state.cvv.valid) {
      list.push("CVV does not appear to be valid");
      return false;
    }
    return true;
  };

  const if_touched = (element, list, fn) => {
    if (!has_been_touched(element)) {
      return;
    }
    return fn(element, list);
  };

  const update_validation_errors = (remove_stale, errors, scroll = false) => {
    let id, list;
    const error_elements = get_all_error_elements();

    for (element of error_elements) {
      element = $(element);
      element.stop();
      element.css({
        height: "",
        margin: "",
        padding: "",
        opacity: "",
      });

      id = element.parents(".box").attr("id");

      list = errors[id] || [];

      if (list.length > 0) {
        const oldText = element.text();
        const newText = list.join(", ") + ".";

        element.text(newText);

        if (element.is(":hidden")) {
          element.slideDown();
        } else if (newText !== oldText) {
          element.css("opacity", 0).animate({ opacity: 1 });
        }
      } else if (remove_stale === true) {
        element.slideUp();
      }
    }

    // Perform scrolling
    if (scroll === true) {
      let scroll_object = null;

      for (id of Object.keys(errors)) {
        list = errors[id];
        if (list.length > 0) {
          scroll_object = $("#" + id);
          break;
        }
      }

      if (scroll_object != null) {
        return $("html, body").animate(
          {
            scrollTop: scroll_object.offset().top,
          },
          600
        );
      }
    }
  };

  // Algorithm: validate elements that have been touched by
  // the user.
  var validate = (remove_stale = true, scroll = false) => {
    let error_id;
    const error_elements = get_all_error_elements();
    const errors = {};

    for (element of error_elements) {
      error_id = $(element).parents(".box").attr("id");
      errors[error_id] = [];
    }

    const elements = get_all_form_elements();

    for (element of elements) {
      element = $(element);
      if (element.data("validate") == null) {
        continue;
      }

      error_id = $(element).parents(".box").attr("id");

      var validations = element.data("validate").split(";");

      if_touched(element, errors[error_id], (element, list) => {
        for (let validation of validations) {
          validation = validation.trim();
          if (validation === "") {
            continue;
          }

          const array = validation.split(":");
          validation = array[0];
          const passed_args = array[1] || "";

          const args = [element];

          for (let arg of passed_args.split(",")) {
            arg = arg.trim();
            if (arg === "") {
              continue;
            }
            args.push(arg);
          }

          args.push(list);

          const fn = eval(`validate_${validation}`);
          if (!fn.apply(this, args)) {
            return;
          }
        }
      });
    }

    update_validation_errors(remove_stale, errors, scroll);

    let num_errors = 0;

    for (let id in errors) {
      const list = errors[id];
      num_errors += list.length;
    }

    return num_errors === 0;
  };

  const send_registration_request = (token, three_d_token) => {
    const options = {
      full_name: (full_name.val() || "").trim(),
      organization: (organization.val() || "").trim(),
      email: (email.val() || "").trim(),
      password: (password.val() || "").trim(),
      token_id: token,
      three_d_token: three_d_token,
      plan_code: get_selected_short_code(),
      g_recaptcha_response: recaptcha_response.val(),
      success: (data, textStatus, jqXHR) => {
        show_success_popup();
        Tracker.track("Conversion", get_tracker_info());
        reset_form();
      },
      failure: (jqXHR, textStatus, errorThrown) => {
        let data;
        try {
          data = JSON.parse(jqXHR.responseText);
        } catch (e) {
          data = {
            failure: `An error has occured. Please try again in a few minutes or contact us at support@${Config.get(
              "host"
            )}.`,
          };
        }

        if (data.three_d_secure) {
          const risk = recurly.Risk();
          const threeDSecure = risk.ThreeDSecure({
            actionTokenId: data.three_d_secure,
          });

          threeDSecure.on("error", (err) => {
            alert(
              "Your card could not be validated. Please try again, or try a different payment method."
            );
          });

          threeDSecure.on("token", (result) => {
            send_registration_request(token, result.id);
          });

          threeDSecure.attach(document.querySelector("#three_d_secure"));
        } else {
          alert(data.failure);
          reset_form();

          Tracker.track("Signup Failure", {
            reason: "/user/register failure",
            severity: "severe",
            status: jqXHR.status,
            responseText: jqXHR.responseText,
            errorThrown,
            textStatus,
            info: get_tracker_info(),
          });
        }
      },
    };

    if (is_education()) {
      options.education = {
        school_name: $("#school_name").val().trim(),
        school_city: $("#school_city").val().trim(),
        school_country: $("#school_country").val().trim(),
        school_web_address: $("#school_web_address").val().trim(),
      };
    }

    if ($("#board").length === 1) {
      options.with_string_identifier = $("#board").text();
    }

    NoteApp.API.User.register(options);
  };

  const send_change_plan_request = (token, three_d_token) => {
    const account_id = parseInt($("#team_id").val());
    const plan_code = get_selected_short_code();

    const options = {
      team_id: account_id,
      token_id: token,
      three_d_token: three_d_token,
      plan_code,
      success() {
        return show_success_popup();
      },
      failure(jqXHR, textStatus, errorThrown) {
        let data;
        try {
          data = JSON.parse(jqXHR.responseText);
        } catch (e) {
          data = {
            failure: `An error has occured. Please try again in a few minutes or contact us at support@${Config.get(
              "host"
            )}.`,
          };
        }

        if (data.three_d_secure) {
          const risk = recurly.Risk();
          const threeDSecure = risk.ThreeDSecure({
            actionTokenId: data.three_d_secure,
          });

          threeDSecure.on("error", (err) => {
            alert(
              "Your card could not be validated. Please try again, or try a different payment method."
            );
          });

          threeDSecure.on("token", (result) => {
            send_change_plan_request(token, result.id);
          });

          threeDSecure.attach(document.querySelector("#three_d_secure"));
        } else {
          alert(data.failure);

          Tracker.track("Upgrade Failure", {
            reason: "/user/register failure",
            severity: "severe",
            status: jqXHR.status,
            responseText: jqXHR.responseText,
            errorThrown,
            textStatus,
            info: get_tracker_info(),
          });
        }
      },
      complete() {
        reset_form();
      },
    };

    NoteApp.API.Team.Billing.change_plan(options);
  };

  const send_recurly_request = (options) => {
    const firstName = matchName(name_on_card.val())[1];
    const lastName = matchName(name_on_card.val())[2];

    $("[data-recurly=first_name]").val(firstName);
    $("[data-recurly=last_name]").val(lastName);

    recurly.token(form[0], (error, token) => {
      if (error) {
        update_validation_errors(
          true,
          { payment: ["Card validation failed"] },
          true
        );
        reset_form();
      } else {
        options.success(token.id);
      }
    });
  };

  const add_options_handlers = () => {
    const options = $(".option").toArray();

    const create_mouseenter_fn = (option) => {
      return () => {
        $(".option").removeClass("highlighted");
        option.addClass("highlighted");
      };
    };

    const create_mouseleave_fn = (option) => {
      return () => {
        $(".option").removeClass("highlighted");
        get_selected_option().addClass("highlighted");
      };
    };

    const create_click_fn = (option) => {
      return () => {
        // Remove all special classes from options or their select links
        $(".option").removeClass("selected highlighted");
        $(".select-option").removeClass("selected");

        // Highlight and select the current option
        option.addClass("selected highlighted");

        // Get the link for the current option, select it, and save
        // the old text
        const link = $("#select_" + option.attr("id"));
        link.addClass("selected");

        // Set the default texts for all select-options
        for (element of $(".select-option").toArray()) {
          element = $(element);
          element.text(element.data("starting_text"));
        }

        // Now set the current link to be selected
        return link.text("Selected");
      };
    };

    for (let option of options) {
      option = $(option);

      const link = $("#select_" + option.attr("id"));

      option.on("mouseenter", create_mouseenter_fn(option));
      option.on("mouseleave", create_mouseleave_fn(option));
      option.on("click", create_click_fn(option));

      link.on("mouseenter", create_mouseenter_fn(option));
      link.on("mouseleave", create_mouseleave_fn(option));
      link.on("click", create_click_fn(option));

      link.data("starting_text", link.text());
    }

    // Ensure the initial selection is all set up and ready
    // to go.
    get_selected_option().click();
  };

  const add_lock = () => {
    SVGHelper.add_path({
      selector: ".lock",
      pathString:
        "M24.875,15.334v-4.876c0-4.894-3.981-8.875-8.875-8.875s-8.875,3.981-8.875,8.875v4.876H5.042v15.083h21.916V15.334H24.875zM10.625,10.458c0-2.964,2.411-5.375,5.375-5.375s5.375,2.411,5.375,5.375v4.876h-10.75V10.458zM18.272,26.956h-4.545l1.222-3.667c-0.782-0.389-1.324-1.188-1.324-2.119c0-1.312,1.063-2.375,2.375-2.375s2.375,1.062,2.375,2.375c0,0.932-0.542,1.73-1.324,2.119L18.272,26.956z",
      transformString: "s0.5",
      attrs: {
        stroke: "none",
        fill: "#FFFFFF",
      },
    });
  };

  const add_form_handlers = () => {
    // Auto-fill name_on_card after full_name is filled in.
    full_name.on("blur", () => {
      if (!has_been_touched(name_on_card)) {
        name_on_card.val(full_name.val());
      }
    });

    submitting = false;

    form.on("submit", (event) => {
      event.preventDefault();

      submit.removeClass("blue");
      submit.addClass("gray");
      submit.text("Please Wait...");

      // Validate the form, don't remove errors if it turns out
      // all elements validate.
      touch_all();
      const valid = validate(false, true);

      if (!valid) {
        // Check to see if it actually completed.
        reset_form();

        Tracker.track("Signup Validation Error", get_tracker_info());

        return false;
      }

      submitting = true;

      const failure = (jqXHR, textStatus, errorThrown) => {
        let data;
        try {
          data = JSON.parse(jqXHR.responseText);
        } catch (e) {
          data = {
            failure: `There was an error communicating with ${Config.get(
              "product_name"
            )}'s servers. Please try again or contact support@${Config.get(
              "host"
            )}.`,
          };
        }

        const message = data.failure;

        // Server returns full sentences
        if (data.failure[data.failure.length - 1] === ".") {
          data.failure = data.failure.substring(0, data.failure.length - 1);
        }

        update_validation_errors(true, { account: [data.failure] }, true);

        return reset_form();
      };

      if (is_upgrading()) {
        if (!needs_billing()) {
          // Upgrading but already have billing on file
          send_change_plan_request();
        } else {
          send_recurly_request({
            success(token) {
              return send_change_plan_request(token);
            },
          });
        }
      } else {
        if (!needs_billing()) {
          // Free signup w/o account
          NoteApp.API.User.email_valid({
            email: email.val(),
            failure,
            success: (data, textStatus, jqXHR) => {
              return send_registration_request();
            },
          });
        } else {
          // Non-free signup w/o account
          NoteApp.API.User.email_valid({
            email: email.val(),
            failure,
            success: (data, textStatus, jqXHR) => {
              return send_recurly_request({
                success(token) {
                  return send_registration_request(token);
                },
              });
            },
          });
        }
      }

      return false;
    });

    return submit.on("click", (event) => {
      event.preventDefault();
      if (submitting === false) {
        return form.submit();
      }
    });
  };

  const pretty_price = function (price_in_cents) {
    let price = price_in_cents / 100;
    if (price % 1 === 0) {
      price = parseInt(price);
    }
    return price;
  };

  const add_plan_change_handlers = function () {
    const options = $(".option");

    return options.on("click", function () {
      const option = $(this);
      const plan = option.data("plan");

      const price = $(".price");
      const period = $("#period");

      if (plan.recur === "month") {
        price.html(`$${pretty_price(plan.price_in_cents)}`);
        return period.text("Monthly");
      } else {
        const monthly = $("#month");
        const monthly_plan = monthly.data("plan");

        price.html(
          `$${pretty_price(
            plan.price_in_cents
          )} <span class='strike'>$${pretty_price(
            monthly_plan.price_in_cents * 12
          )}</span>`
        );
        return period.text("Annual");
      }
    });
  };

  add_lock();
  add_options_handlers();
  add_form_handlers();
  add_plan_change_handlers();

  SVGHelper.add_questionmarks();
};

export { signup };
