import Raphael from "raphael";

const tour = function() {
  const line_attrs = {
    stroke: "#777",
    "stroke-width": 4
  };

  const text_attrs = {
    "font-family": "PermanentMarker Regular",
    fill: "#777",
    "font-size": 30,
    stroke: "none"
  };

  const add_icons = () => {
    const icons = [
      {
        elements: $(".icon.board"),
        path:
          "M15.57,20.273h0.854v-1.705H15.57V20.273zM15.57,23.686h0.854V21.98H15.57V23.686zM15.57,27.096h0.854v-1.705H15.57V27.096zM15.57,29.689h0.854V28.8H15.57V29.689zM15.57,16.865h0.854V15.16H15.57V16.865zM15.57,3.225h0.854V1.52H15.57V3.225zM15.57,6.635h0.854V4.93H15.57V6.635zM15.57,10.045h0.854V8.34H15.57V10.045zM15.57,13.455h0.854V11.75H15.57V13.455zM18.41,3.327V25.46h12.015L18.41,3.327zM19.264,6.68l9.729,17.93h-9.729V6.68zM13.535,25.46V3.327L1.521,25.46H13.535z"
      },
      {
        elements: $(".icon.lightning"),
        path:
          "M22.727,18.242L4.792,27.208l8.966-8.966l-4.483-4.484l17.933-8.966l-8.966,8.966L22.727,18.242z"
      },
      {
        elements: $(".icon.box"),
        path:
          "M17.078,22.004l-1.758-4.129l-2.007,4.752l-7.519-3.289l0.174,3.905l9.437,4.374l10.909-5.365l-0.149-4.989L17.078,22.004zM29.454,6.619L18.521,3.383l-3.006,2.671l-3.091-2.359L1.546,8.199l3.795,3.048l-3.433,5.302l10.879,4.757l2.53-5.998l2.257,5.308l11.393-5.942l-3.105-4.709L29.454,6.619zM15.277,14.579l-9.059-3.83l9.275-4.101l9.608,3.255L15.277,14.579z"
      },
      {
        elements: $(".icon.upload"),
        path:
          "M24.345,13.904c0.019-0.195,0.03-0.392,0.03-0.591c0-3.452-2.798-6.25-6.25-6.25c-2.679,0-4.958,1.689-5.847,4.059c-0.589-0.646-1.429-1.059-2.372-1.059c-1.778,0-3.219,1.441-3.219,3.219c0,0.21,0.023,0.415,0.062,0.613c-2.372,0.391-4.187,2.436-4.187,4.918c0,2.762,2.239,5,5,5h2.312c-0.126-0.266-0.2-0.556-0.2-0.859c0-0.535,0.208-1.04,0.587-1.415l4.325-4.329c0.375-0.377,0.877-0.585,1.413-0.585c0.54,0,1.042,0.21,1.417,0.587l4.323,4.329c0.377,0.373,0.585,0.878,0.585,1.413c0,0.304-0.073,0.594-0.2,0.859h1.312c2.762,0,5-2.238,5-5C28.438,16.362,26.672,14.332,24.345,13.904z M16.706,17.916c-0.193-0.195-0.45-0.291-0.706-0.291s-0.512,0.096-0.707,0.291l-4.327,4.33c-0.39,0.389-0.389,1.025,0.001,1.414l0.556,0.555c0.39,0.389,0.964,0.449,1.276,0.137s0.568-0.119,0.568,0.432v1.238c0,0.549,0.451,1,1,1h3.265c0.551,0,1-0.451,1-1v-1.238c0-0.551,0.256-0.744,0.569-0.432c0.312,0.312,0.887,0.252,1.276-0.137l0.556-0.555c0.39-0.389,0.39-1.025,0.001-1.414L16.706,17.916z"
      }
    ];

    icons.map(icon => {
      for (let element of icon.elements.toArray()) {
        element = $(element);

        const paper = Raphael(element[0], element.width(), element.height());

        const path = paper.path(icon.path).attr({
          fill: "#373737",
          stroke: "none"
        });

        const bbox = path.getBBox();
        const new_center = {
          left: element.width() / 2 - (bbox.x + bbox.width / 2),
          top: element.height() / 2 - (bbox.y + bbox.height / 2)
        };

        path.transform(`T${new_center.left},${new_center.top}`);
      }
    });
  };

  const add_arrow = (element, flip = false) => {
    const path_string =
      "M21.871,9.814 15.684,16.001 21.871,22.188 18.335,25.725 8.612,16.001 18.335,6.276z";

    const arrow_attrs = {
      fill: "#BBB",
      stroke: "none"
    };

    const hover_attrs = {
      fill: "#373737"
    };

    const paper = Raphael(element[0], element.width(), element.height());

    // Draw a curve upwards and to the right
    const arrow = paper.path(path_string).attr(arrow_attrs);

    const bbox = arrow.getBBox();
    const new_center = {
      left: element.width() / 2 - (bbox.x + bbox.width / 2),
      top: element.height() / 2 - (bbox.y + bbox.height / 2)
    };

    arrow.transform(
      `T${new_center.left},${new_center.top}s2.4${flip ? "R180" : undefined}`
    );

    element.on("mouseenter", () => arrow.attr(hover_attrs));

    element.on("mouseleave", () => arrow.attr(arrow_attrs));
  };

  const add_panel_one_items = () => {
    const element = $(".panel.one .paper");

    const paper = Raphael(element[0], element.width(), element.height());

    const text = paper.text(250, 300, "One-click").attr(text_attrs);

    const bbox = text.getBBox();

    const startX = bbox.x - 10;
    const startY = bbox.y + bbox.height / 2;
    const endX = 100;
    const endY = 240;
    const inflectionX = 90;
    const inflectionY = bbox.y + 30;

    const line = paper
      .path(
        `M${startX},${startY}S${inflectionX},${inflectionY},${endX},${endY}`
      )
      .attr(line_attrs);

    const bend = paper
      .path(
        `M${endX - 10},${endY + 10}L${endX},${endY}L${endX + 10},${endY + 10}`
      )
      .attr(line_attrs);
    return bend.transform("T-0.5,0r1");
  };

  //startX = bbox.x + bbox.width + 10
  //startY = bbox.y + (bbox.height / 2)
  //endX = 410
  //endY = 320
  //inflectionX = 410
  //inflectionY = bbox.y + 30

  //right_line = (paper.path "M#{startX},#{startY}S#{inflectionX},#{inflectionY},#{endX},#{endY}").attr line_attrs

  //right_bend = (paper.path "M#{endX-10},#{endY+10}L#{endX},#{endY}L#{endX+10},#{endY+10}").attr line_attrs
  //right_bend.transform "T-0.5,0r1"

  const add_panel_two_items = () => {
    const element = $(".panel.two .paper");

    const paper = Raphael(element[0], element.width(), element.height());

    const text = paper.text(210, 298, "Sent to Everyone").attr(text_attrs);

    const bbox = text.getBBox();

    const start_padding = 20;
    let startX = bbox.x + bbox.width / 2 - start_padding;
    const startY = 228;

    let endX = bbox.x + bbox.width / 4;
    const endY = bbox.y - 10;

    let line = paper
      .path(`M${startX},${startY}L${endX},${endY}`)
      .attr(line_attrs);

    let bend = paper
      .path(
        `M${endX - 10},${endY - 10}L${endX},${endY}L${endX + 10},${endY - 10}`
      )
      .attr(line_attrs);
    bend.transform("T2,2r45");

    startX += start_padding;
    endX += bbox.width / 4;

    line = paper.path(`M${startX},${startY}L${endX},${endY}`).attr(line_attrs);

    bend = paper
      .path(
        `M${endX - 10},${endY - 10}L${endX},${endY}L${endX + 10},${endY - 10}`
      )
      .attr(line_attrs);
    bend.transform("T0,2");

    startX += start_padding;
    endX += bbox.width / 4;

    line = paper.path(`M${startX},${startY}L${endX},${endY}`).attr(line_attrs);

    bend = paper
      .path(
        `M${endX - 10},${endY - 10}L${endX},${endY}L${endX + 10},${endY - 10}`
      )
      .attr(line_attrs);

    bend.transform("T-2,2r-45");
  };

  const add_panel_three_items = () => {
    const element = $(".panel.three .paper");

    const paper = Raphael(element[0], element.width(), element.height());

    const text = paper.text(250, 310, "Drag & Drop").attr(text_attrs);

    const bbox = text.getBBox();
    const endX = 145;
    const endY = 260;

    const line = paper
      .path(
        `M${bbox.x - 10},${bbox.y + bbox.height / 2}S75,${bbox.y +
          30},${endX},${endY}`
      )
      .attr(line_attrs);

    const bend = paper
      .path(
        `M${endX - 10},${endY + 10}L${endX},${endY}L${endX + 10},${endY + 10}`
      )
      .attr(line_attrs);
    return bend.transform("T-2,-3r45");
  };

  let current_page = 1;
  const last_page = $(".panel").length;

  const container = $(".container");

  const left_arrow = $(".arrow-container.left .arrow");
  const right_arrow = $(".arrow-container.right .arrow");

  add_arrow(left_arrow);
  add_arrow(right_arrow, true);

  const select_tab_for_current_page = () => {
    $(".tabs .tab").removeClass("selected");

    const tab = $(".tabs .tab")[current_page - 1];

    $(tab).addClass("selected");
  };

  const animate_to_current_page = (duration = 500) => {
    container.stop();
    container.animate(
      {
        left: -((current_page - 1) * $(".lightbox .block").width())
      },
      {
        duration
      }
    );
  };

  right_arrow.on("click", () => {
    current_page += 1;
    let duration = 500;

    if (current_page > last_page) {
      current_page = 1;
      duration = 700;
    }

    animate_to_current_page(duration);
    select_tab_for_current_page();
  });

  left_arrow.on("click", () => {
    current_page -= 1;
    let duration = 500;

    if (current_page < 1) {
      current_page = last_page;
      duration = 700;
    }

    animate_to_current_page(duration);
    select_tab_for_current_page();
  });

  const create_tab_handler = (tab, page) => {
    return () => {
      current_page = page;
      animate_to_current_page();
      return select_tab_for_current_page();
    };
  };

  const iterable = $(".tabs .tab");
  for (let i = 0; i < iterable.length; i++) {
    const tab = iterable[i];
    const page = i + 1; // 1,2,3..
    $(tab).on("click", create_tab_handler(tab, page));
  }

  add_icons();
  add_panel_one_items();
  add_panel_two_items();
  add_panel_three_items();
};

export { tour };
