import { NoteApp } from "src/api/v1";

const contact = () => {
  const email = $("#email");
  const body = $("#body");

  // Focus the email text box if somebody click "From:"
  $(".input-wrapper").on("click", () => email.trigger("focus"));

  const form = $("#form");

  const send = $("#send");
  const sendText = send.text();

  const error = $("#error");

  let submitting = false;

  const submit = () => {
    if (submitting) {
      return;
    }

    submitting = true;

    send.removeClass("blue");
    send.addClass("gray");
    send.text("Please wait...");

    const success = (data, textStatus, jqXHR) => {
      body.val("");

      error.addClass("success");
      error.text("Your message was successfully sent. We'll respond shortly.");
    };

    const failure = (jqXHR, textStatus, errorThrown) => {
      let data;
      try {
        data = JSON.parse(jqXHR.responseText);
      } catch (e) {
        data = {
          failure: "Server error. Please try again later or contact support."
        };
      }

      error.removeClass("success");
      error.text(data.failure);
    };

    const complete = function() {
      submitting = false;

      send.removeClass("gray");
      send.addClass("blue");
      send.text(sendText);

      if (error.is(":hidden")) {
        error.slideDown();
      }
      if (!error.is(":hidden")) {
        error.hide();
        error.fadeIn();
      }
    };

    NoteApp.API.Contact.makeContact({
      from_email: email.val().trim(),
      body: body.val().trim(),
      success,
      failure,
      complete
    });
  };

  send.on("click", submit);
  form.on("submit", event => {
    event.preventDefault();
    submit();
    return false;
  });
};

export { contact };
