import { YesNoModal } from "./YesNoModal";

class UpgradeModal extends YesNoModal {
  constructor(team_id, header, text) {
    super();

    this.options = {
      header: header || "Upgrade now!",
      text:
        text ||
        "Whoops. You need to upgrade to add more boards. Upgrade now to better organize information for yourself and your team.",
      className: "upgrade white",
      yesfn() {
        window.location.href = `/pricing?team_id=${team_id}`;
      },
      nofn() {
        window.location.href = `/pricing?team_id=${team_id}`;
      },
      closefn() {},
      closeable: true,
      yesText: "Upgrade me now!",
      noText: "Learn more",
      hideAfter: false
    };
  }
}

export { UpgradeModal };
