/*
 * decaffeinate suggestions:
 * DS207: Consider shorter variations of null checks
 */
class AnimationQueue {
  constructor(queue, loop, currentFrame) {
    this.queue = queue;
    this.isPaused = true;
    this.shouldPause = true;
    this.currentFrame = currentFrame || 0;
    this.loop = loop == null ? true : loop;
  }

  play(waittime) {
    const self = this;
    waittime = waittime || 0;
    this.shouldPause = false;
    if (this.isPaused) {
      this.isPaused = false;
      setTimeout(() => self.callNext()(), waittime);
    }
  }

  pause() {
    this.shouldPause = true;
  }

  reset() {
    this.currentFrame = 0;
    this.shouldPause = true;
  }

  callNext(frameIndex) {
    if (!frameIndex) {
      frameIndex = this.currentFrame;
    }

    this.currentFrame = frameIndex;
    if (this.shouldPause) {
      this.isPaused = true;
      return function() {};
    }
    return () => {
      this.isPaused = false;
      if (!this.queue[frameIndex]) {
        this.reset();
        if (this.loop === true) {
          this.shouldPause = false;
          this.callNext()();
        } else {
          this.isPaused = true;
        }
        return;
      }

      const next = this.callNext(frameIndex + 1);
      let current = this.queue[frameIndex];

      if (current.length === 0) {
        current = cb => {
          this.queue[frameIndex]();
          return cb();
        };
      }

      return current(next);
    };
  }

  static wait(timeout) {
    return callback => setTimeout(callback, timeout);
  }
}

export { AnimationQueue };
