import { Config } from "src/config";
import { Tracker } from "src/utils/tracker";

/* Config */

(window.config_to_add || []).forEach(map => Config.add(map));

/* Tracker */

Tracker.identify();
Tracker.track("Viewed: " + window.location.pathname, {
  url: window.location.href,
  action: window.tracker_action
});
