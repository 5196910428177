import { NoteApp } from "src/api/v1";
import { Config } from "src/config";

const teams = () => {
  $(".row .cta").on("click", function(event) {
    const cta = $(this);
    const row = $(cta.parents(".row"));

    const account_id = row.data("account-id");

    const choice = confirm(
      "Are you sure you want to leave this team? You'll need to ask the team's administrator to invite you if you'd like to become a member in the future. \r\n\r\nPress OK to leave this team, or cancel to stay a member."
    );

    if (!choice) {
      return;
    }

    const success = () => row.slideUp(() => row.remove());

    const failure = (jqXHR, textStatus, errorThrown) => {
      try {
        const data = JSON.parse(jqXHR.responseText);
        alert(data.failure);
      } catch (e) {
        alert(
          "There was an error removing you from this team. If you continue to receive this error please contact support."
        );
      }
    };

    NoteApp.API.Team.remove_member({
      team_id: account_id,
      user_id: Config.get("user").id,
      success,
      failure
    });
  });
};

export { teams };
