import { AnimationQueue } from "src/utils/AnimationQueue";
import { SVGHelper } from "src/utils/SVGHelper";

const index = () => {
  const note1 = $(".note.red");
  const note2 = $(".note.blue");
  const note3 = $(".note.green");
  const note4 = $(".note.yellow");
  const note5 = $(".note.purple");

  const noteAttrs = {
    fill: "#E5E170",
    stroke: "none"
  };

  SVGHelper.create_note({
    selector: note1,
    transformString: "s3",
    attrs: noteAttrs
  });

  SVGHelper.create_note({
    selector: note2,
    transformString: "s3",
    attrs: noteAttrs
  });

  SVGHelper.create_note({
    selector: note3,
    transformString: "s3",
    attrs: noteAttrs
  });

  SVGHelper.create_note({
    selector: note4,
    transformString: "s3",
    attrs: noteAttrs
  });

  SVGHelper.create_note({
    selector: note5,
    transformString: "s3",
    attrs: noteAttrs
  });

  const changeNoteColor = (element, color) =>
    $(element)
      .find("path")
      .attr("fill", color);

  const video = $(".video");
  const text = video.find(".text");

  const annotation = $(".annotation");

  const tim = $(".tim");
  const chris = $(".chris");
  const bridgette = $(".bridgette");

  const play = $(".play");
  const replay = $(".replay");

  const aq = new AnimationQueue(
    [
      function() {
        // Remove all styles to put things back in their original position
        // and reset items back to their original states.
        video
          .find("div")
          .hide()
          .removeAttr("style");
        $(".note .edit").hide();

        changeNoteColor($(".note"), noteAttrs.fill);
        text.html("&#8592; Unlimited board space &#8594;");

        note1.css({ top: -90, left: -230 });
        note2.css({ top: 70, left: -150 });
        note3.css({ top: -70, left: 40 });
        note4.css({ top: 100, left: 70 });
        note5.css({ left: 270 });

        tim.hide();
        chris.hide();
        bridgette.hide();

        // Annotation is slightly different from the rest.
        // It's shown/hidden by toggling it's width.
        annotation.show();
        annotation.animate({ width: "toggle" }, 0);

        return text.fadeIn();
      },
      AnimationQueue.wait(3000),
      callback =>
        text.fadeOut(function() {
          text.text("Unlimited notes");
          return text.fadeIn(callback);
        }),
      AnimationQueue.wait(700),
      () =>
        note1.fadeIn(300, () =>
          note2.fadeIn(300, () =>
            note3.fadeIn(300, () =>
              note4.fadeIn(300, () => note5.fadeIn(300, function() {}))
            )
          )
        ),
      AnimationQueue.wait(3000),
      () =>
        text.fadeOut(200, function() {
          text.text("Place them anywhere");
          return text.fadeIn();
        }),
      AnimationQueue.wait(300),
      function() {
        note1.animate({ top: "-160", left: "-300" }, 300);
        note2.animate({ top: "140", left: "-150" }, 300);
        note3.animate({ top: "-140", left: "140" }, 300);
        note4.animate({ top: "200", left: "100" }, 300);
        return note5.animate({ left: "400" }, 300);
      },
      AnimationQueue.wait(3000),
      () =>
        text.fadeOut(200, function() {
          text.text("Or organize in groups");
          return text.fadeIn();
        }),
      AnimationQueue.wait(300),
      function() {
        note1.animate({ top: "-50", left: "-320" });
        note2.animate({ top: "50", left: "-320" });
        note3.animate({ top: "-50", left: "-220" });
        // Leave note4 where it is.
        return note5.animate({ top: "50", left: "-220" });
      },
      AnimationQueue.wait(3000),
      () =>
        text.fadeOut(200, function() {
          text.text("Invite others");
          return text.fadeIn();
        }),
      AnimationQueue.wait(700),
      () => tim.fadeIn(600, () => chris.fadeIn(600, () => bridgette.fadeIn())),
      AnimationQueue.wait(3000),
      () =>
        text.fadeOut(200, function() {
          text.text("Work together");
          return text.fadeIn();
        }),
      AnimationQueue.wait(1500),
      function() {
        tim.animate({ top: 180 });
        chris.animate({ top: 300 });
        bridgette.animate({ top: 300 });

        note4.hide();
        note4.css({ top: 0, left: 250 });
        return note4.fadeIn();
      },
      AnimationQueue.wait(800),
      function() {
        annotation.text("Tim added a note");
        return annotation.animate({ width: "toggle" });
      },
      AnimationQueue.wait(2000),
      function() {
        tim.animate({ top: 300 });
        chris.css({ left: 500 });
        chris.animate({ top: 180 });
        changeNoteColor(".note.yellow", "#75D8ED");
        return annotation.animate({ top: 407 }, function() {
          annotation.css({ top: "" });
          annotation.animate({ width: "toggle" }, 0);
          annotation.text("Chris changed the color");
          return annotation.animate({ width: "toggle" });
        });
      },
      AnimationQueue.wait(2000),
      function() {
        chris.animate({ top: 300 });
        bridgette.css({ left: 500 });
        bridgette.animate({ top: 180 });
        note4.find(".edit").slideDown(1500);
        return annotation.animate({ top: 407 }, function() {
          annotation.css({ top: "" });
          annotation.animate({ width: "toggle" }, 0);
          annotation.text("Bridgette edited a note");
          return annotation.animate({ width: "toggle" });
        });
      },
      AnimationQueue.wait(3000),
      () =>
        text.fadeOut(200, function() {
          text.text("See changes in real-time");
          return text.fadeIn();
        }),
      function() {
        bridgette.animate({ top: 300 });
        return annotation.animate({ top: 407 });
      },
      AnimationQueue.wait(800),
      () => note3.animate({ top: -80, left: -150 }),
      AnimationQueue.wait(800),
      () => changeNoteColor(".note.purple", "#EA7373"),
      AnimationQueue.wait(800),
      () => note5.animate({ top: 80, left: 0 }),
      AnimationQueue.wait(800),
      () => note1.fadeOut(),
      AnimationQueue.wait(800),
      () => changeNoteColor(".note.blue", "#73EA9A"),
      AnimationQueue.wait(800),
      () =>
        note2.animate({ top: 0, left: -250 }, () =>
          note2.find(".edit").slideDown(1500)
        ),
      AnimationQueue.wait(3000),
      () => video.children().fadeOut(),
      AnimationQueue.wait(500),
      () =>
        $(".done").fadeIn(function() {
          text.text("And much more.");
          text.show();
          return text.animate({ top: -40 });
        }),
      AnimationQueue.wait(2000),
      () => replay.fadeIn()
    ],
    false
  );

  SVGHelper.add_path({
    selector: play,
    pathString: "M6.684,25.682L24.316,15.5L6.684,5.318V25.682z",
    transformString: "s3",
    translate: "3,0",
    attrs: {
      fill: "#FFF",
      stroke: "none"
    }
  });

  SVGHelper.add_path({
    selector: replay,
    pathString:
      "M24.083,15.5c-0.009,4.739-3.844,8.574-8.583,8.583c-4.741-0.009-8.577-3.844-8.585-8.583c0.008-4.741,3.844-8.577,8.585-8.585c1.913,0,3.665,0.629,5.09,1.686l-1.782,1.783l8.429,2.256l-2.26-8.427l-1.89,1.89c-2.072-1.677-4.717-2.688-7.587-2.688C8.826,3.418,3.418,8.826,3.416,15.5C3.418,22.175,8.826,27.583,15.5,27.583S27.583,22.175,27.583,15.5H24.083z",
    transformString: "S-1,1",
    attrs: {
      fill: "#FFF",
      stroke: "none"
    }
  });

  const start_video = () =>
    video.children().fadeOut(() =>
      setTimeout(function() {
        play.remove();
        return aq.play();
      }, 700)
    );

  play.on("click", start_video);
  replay.on("click", start_video);
};

export { index };
