import { NoteApp } from "src/api/v1";

const settings = () => {
  const submit = $("#submit");
  const submitText = submit.text();

  const error = $(".error");
  const errorText = error.text();

  const name = $("#name");

  submit.on("click", () => {
    submit.text("Please wait...");
    submit.removeClass("blue");
    submit.addClass("gray");

    const success = () => {
      error.addClass("success");
      error.text("Name changed successfully.");
      error.hide();
      error.fadeIn();
    };

    const failure = (jqXHR, textStatus, errorThrown) => {
      error.removeClass("success");
      error.text(errorText);
      error.hide();
      error.fadeIn();
    };

    const complete = () => {
      submit.text(submitText);
      submit.removeClass("gray");
      submit.addClass("blue");
    };

    NoteApp.API.Team.change_name({
      team_id: name.data("team-id"),
      name: name.val().trim(),
      success,
      failure,
      complete
    });
  });
};

export { settings };
