import { NoteApp } from "src/api/v1";
import { Config } from "src/config";

import { CTAButton } from "src/controls/buttons/CTAButton";

const payments = () => {
  const currentInfo = $("#current_info");
  const recurlyUpdate = $("#recurly_update");
  const account_id = $("#account_id").val();

  var lookForClientSideErrors = function () {
    // Show a client side error in the server side error box
    // when a client side error occurs.
    const invalidFields = $(".recurly-hosted-field-invalid");
    const serverError = $(".server_errors");

    if (invalidFields.length > 0 && !serverError.hasClass("any client_error")) {
      serverError
        .removeClass("none")
        .addClass("any client_error")
        .empty()
        .append(
          "<div class='error'>Please correct the highlighted fields and try again.</div>"
        );
    } else if (
      invalidFields.length === 0 &&
      serverError.hasClass("client_error")
    ) {
      serverError
        .empty()
        .removeClass("any client_error")
        .addClass("none");
    }

    setTimeout(lookForClientSideErrors, 100);
  };

  lookForClientSideErrors;

  const showLoading = () => {
    currentInfo.empty();

    const div = $(document.createElement("div"));
    div.addClass("ei-loading-big-white");
    div.css({
      width: "50%",
      height: "100px",
      "background-position": "center",
      "background-repeat": "no-repeat"
    });

    currentInfo.append(div);
  };

  const hideLoading = () => {
    $(".ei-loading-big-white").hide();
  };

  const requestBillingInfo = () => {
    recurlyUpdate.hide();
    showLoading();
    NoteApp.API.Team.Billing.overview({
      team_id: account_id,
      success(data, textStatus, xmlHttpRequest) {
        hideLoading();
        showBillingInfo(data);
      },

      error(jqXHR, textStatus, errorThrown) {
        alert(
          "Error retrieving billing information. Please try again later or contact support@notepap.com."
        );
      }
    });
  };

  const matchName = name => {
    return name.trim().match(/^([^ ]+).* ([^ ]+)$/);
  };

  const showBillingInfo = data => {
    currentInfo.empty();

    const state = data["state"];
    const credit_card = data["credit_card"];
    const on_file = credit_card["on_file"];

    const cardInfo = $(document.createElement("div"));
    cardInfo.addClass("card-info");
    const cardError = $(document.createElement("div"));
    cardError.addClass("card-error");
    cardError.hide();

    const editButton = CTAButton.createSmall(
      "Update &raquo;",
      "update",
      "blue"
    );
    editButton.on("click", () => showRecurlyUpdateBillingForm());

    if (on_file) {
      const lastFour = credit_card["last_four"];
      let type = credit_card["type"];
      const expiresMonth = credit_card["expires_month"];
      const expiresYear = credit_card["expires_year"];

      type = type
        .toLowerCase()
        .replace(" ", "_")
        .replace("'", "");
      const today = new Date();
      const currentMonth = today.getMonth() + 1;
      const currentYear = today.getFullYear();
      const expired = expiresMonth < currentMonth && expiresYear <= currentYear;
      const expiresSoon =
        expiresMonth <= currentMonth && expiresYear <= currentYear;

      // Start creating UI.
      const cardWrapper = $(document.createElement("div"));
      cardWrapper.addClass("card-wrapper");
      const cardImage = $(document.createElement("div"));
      cardImage.addClass("card-image " + type + " ei-" + type + "-large");
      const cardNumber = $(document.createElement("div"));
      cardNumber.addClass("card-number");
      const format =
        type === "american_express" ? "XXXX-XXXXXX-X" : "XXXX-XXXX-XXXX-";
      cardNumber.html(format + lastFour);
      cardWrapper.append(cardImage);
      cardWrapper.append(cardNumber);
      cardInfo.append("<br>");
      cardInfo.append("<br>");
      cardInfo.append(cardWrapper);
      cardInfo.append("<br>");
      cardInfo.append("Expires: " + expiresMonth + " / " + expiresYear);
      if (expired) {
        cardInfo.append("&nbsp;<span class='expires-message'>(expired!)</div>");
      } else if (expiresSoon) {
        cardInfo.append(
          "&nbsp;<span class='expires-message'>(expires soon!)</div>"
        );
      }
      cardInfo.append(editButton);
    } else {
      cardError.show();
      if (state !== "closed") {
        cardError.html("We don't have a card on file for this account.<br>");
        CTAButton.changeText(editButton, "Add card");
        cardError.append(editButton);
      } else {
        cardError.html(
          "Your account has been closed.<br><br>To renew, please contact <a href='mailto:support@noteapp.com' target='_blank'>support@noteapp.com</a>."
        );
      }
    }

    currentInfo.append(cardInfo);
    currentInfo.append(cardError);

    currentInfo.show();
  };

  const form = $("#update_billing_info");

  const showRecurlyUpdateBillingForm = () => {
    currentInfo.empty();
    recurlyUpdate.show();

    $(".cvv_glyph").on("click", () =>
      window.open("http://www.cvvnumber.com/cvv.html")
    );

    $("#cancel_button").on("click", () => requestBillingInfo());

    $("#save_button").on("click", () => {
      $("#save_button span").html("Please wait...");
      $(".cta-container.save").addClass("disabled");

      const success = (data, textStatus, jqXHR) => {
        location.reload();
      };

      const failure = token_id => (jqXHR, textStatus, errorThrown) => {
        let data;
        try {
          data = JSON.parse(jqXHR.responseText);
        } catch (e) {
          data = {
            failure: "Server error. Please try again later or contact support."
          };
        }
        if (data.three_d_secure) {
          const risk = recurly.Risk();
          const threeDSecure = risk.ThreeDSecure({
            actionTokenId: data.three_d_secure
          });

          threeDSecure.on("error", err => {
            alert(
              "Your card could not be validated. Please try again, or try a different payment method."
            );
          });

          threeDSecure.on("token", result => {
            NoteApp.API.Team.Billing.change_token({
              team_id: account_id,
              token_id,
              three_d_token: result.id,
              success,
              failure: failure(token_id)
            });
          });

          threeDSecure.attach(document.querySelector("#three_d_secure"));
        } else {
          alert(data.failure);
        }

        $("#save_button span").html("Save");
        $(".cta-container.save").removeClass("disabled");
      };

      const name_on_card = $("#name_on_card").val();
      const matchedName = matchName(name_on_card);

      if (!name_on_card || !matchedName || matchedName.length < 3) {
        alert("Please fill in your name.");
        $("#save_button span").html("Save");
        $(".cta-container.save").removeClass("disabled");
        return;
      }

      const firstName = matchedName[1];
      const lastName = matchedName[2];

      $("[data-recurly=first_name]").val(firstName);
      $("[data-recurly=last_name]").val(lastName);

      recurly.token(form[0], (error, token) => {
        if (error) {
          alert("The card details entered are invalid. Please try again.");
          $("#save_button span").html("Save");
          $(".cta-container.save").removeClass("disabled");
        } else {
          NoteApp.API.Team.Billing.change_token({
            team_id: account_id,
            token_id: token.id,
            success,
            failure: failure(token.id)
          });
        }
      });
    });
  };

  // Request billing info when the page loads.
  requestBillingInfo();
};

export { payments };
